import "@draft-js-plugins/inline-toolbar/lib/plugin.css";
import {useState, useEffect, useRef} from "react";
import {DraftHandleValue, EditorState } from "draft-js";
import Editor from "@draft-js-plugins/editor";
import createInlineToolbarPlugin from "@draft-js-plugins/inline-toolbar";
import { convertHTMLToContentState } from "@lib/utils";
import AddButtonToolbar from "@components/case-page-details/add-button-toolbar";

const styleMap = {
  'MARK': {
    background: "yellow",
  },
};

const inlineToolbarPlugin = createInlineToolbarPlugin();
const {InlineToolbar} = inlineToolbarPlugin;
const plugins = [inlineToolbarPlugin];


const PageDetailsEditor = ({ uploadedText }: { uploadedText: string }) => {
  const htmlContent = convertHTMLToContentState(uploadedText)
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createWithContent(htmlContent)
  );
  const editorRef = useRef<Editor | null>(null);

  useEffect(() => {
    const htmlContent = convertHTMLToContentState(uploadedText)
      setEditorState(EditorState.createWithContent(htmlContent));
  }, [uploadedText]);


  const onChange = (newEditorState: EditorState) => {
    setEditorState(newEditorState);
  };
  const handleBeforeInput = (_chars: string, _editorState: EditorState, _eventTimeStamp: number): DraftHandleValue => {
    // Prevent inserting characters
    return 'handled';
  };

  const focus = () => {
    editorRef.current?.focus();
  };

  return (
      <div onClick={focus} className="text-sm">
          <Editor
            editorKey="CustomInlineToolbarEditor"
            editorState={editorState}
            customStyleMap={styleMap}
            onChange={onChange}
            handleBeforeInput={handleBeforeInput}
            ref={editorRef}
            plugins={plugins}
          />
          <InlineToolbar>
            {(externalProps) => (
              <div>
                <AddButtonToolbar {...externalProps} uploadedText={uploadedText} />
              </div>
            )}
          </InlineToolbar>
      </div>
  );
};

export default PageDetailsEditor;
