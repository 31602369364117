import * as mammoth from "mammoth";


export async function readDocxDocument(document: ArrayBuffer): Promise<string> {
  const result = await mammoth.convertToHtml({ arrayBuffer: document }, {
    convertImage: mammoth.images.imgElement(async (_image) => {
      return {
        src: '',  // shorten image url
      };
    }),
  });

  let html = result.value;
  html = html.replace(/<img[^>]*>/g, "");  // remove img

  return html;
}
