import { IcdClass } from "@models/icd-class";
import { IcdModifier, IcdModifierClass } from "@models/icd-modifier";
import { CodeSearchResult } from "@models/code-search-result";
import * as IcdAccess from "@access/icd.access";
import { QueryListOptions, QueryListResponse } from "./base-query.service";

export const getIcdClass = async (id: string): Promise<{ class?: IcdClass, modifierClasses?: IcdModifierClass[] }> => {
  return await IcdAccess.getIcdClass(id);
};

export const getIcdSubClasses = async (superclassId: string | null, options?: QueryListOptions): Promise<QueryListResponse<IcdClass>> => {
  return await IcdAccess.getIcdSubClasses(superclassId, options);
};

export const getIcdModifier = async (id: string): Promise<IcdModifier> => {
  return await IcdAccess.getIcdModifier(id);
};

export const searchIcd = async (searchTerm: string, options: Pick<QueryListOptions, 'limit'>): Promise<CodeSearchResult[]> => {
  return await IcdAccess.searchIcd(searchTerm, options);
};
