import {useState} from "react";
import UploadModal from "@components/dashboard/components/upload-modal";
import { useUploadedFileStore } from "@store/uploaded-file.store";
import * as MedicalReportService from "@services/medical-report.service";
import { useTranslation } from "react-i18next";


const UploadFile = ({
  fileUploaded
}: {
  fileUploaded?: () => void
}) => {
  const {t} = useTranslation();
  const [open, setOpen] = useState(false);
  const {content, setContent, setFileName} = useUploadedFileStore()
 
  const handleUploadFiles = (files: FileList | null) => {
    const file = files?.[0];
    if (!file) {
      console.error(new Error("No file selected"));
      return;
    }

    // Check if the selected file is a DOCX
    if (file.type !== "application/vnd.openxmlformats-officedocument.wordprocessingml.document" && file.type !== "application/pdf") {
      console.error(new Error("Please select a DOCX or PDF file"));
      return;
    }

    const reader = new FileReader();

    reader.onload = async (event) => {
      const arrayBuffer = event.target?.result as ArrayBuffer;

      try {
        setFileName(file.name);
        const report: string = await MedicalReportService.parseMedicalReport(file.name, arrayBuffer);
        setContent(report);
        setOpen(true);
      } catch (error) {
        console.error(new Error("Error extracting text from file"), error);
      }
    };

    reader.onerror = (event) => {
      console.error(
        new Error(
          "Error reading file: " +
            (event.target?.error?.message || "Unknown error")
        )
      );
    };

    // Read the file content as an ArrayBuffer
    reader.readAsArrayBuffer(file);
  };

  return (
    <>
      {content && (
        <UploadModal open={open} content={content} setOpen={setOpen} fileUploaded={fileUploaded} />
      )}        

      <div className="relative flex justify-start items-center">
        <input
          id="fileUpload"
          className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
          accept=".docx,.pdf"
          type="file"
          onChange={(e) => handleUploadFiles(e.target.files)}
          hidden={true}
        />
        <label
          htmlFor="fileUpload"
          className="bg-red-500 text-white rounded-lg px-4 py-2 flex items-center justify-center space-x-2 cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinejoin="round"
            className="text-white"
          >
            <path d="m5 12 7-7 7 7"></path>
            <path d="M12 19V5"></path>
          </svg>
          <span>{t("upload")}</span>
        </label>
      </div>
    </>
  );
};

export default UploadFile;
