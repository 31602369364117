import i18n from "@config/i18n";
import { cn } from "@lib/utils";
import { Button } from "@ui/button";
import { Calendar } from "@ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "@ui/popover";
import { format } from "date-fns";
import { de, enGB } from "date-fns/locale";
import { t } from "i18next";
import { Calendar as CalendarIcon } from "lucide-react";
import * as React from "react";
import { useState } from "react";
import { Matcher } from "react-day-picker";
import { Badge } from "./badge";
import { Icons } from "@components/icons";

export function SingleDatePicker({
  className,
  dateProps,
  onSave,
  disabled,
}: React.HTMLAttributes<HTMLDivElement> & {
  onValueChange?: (
    date?: Date,
  ) => { warning?: boolean; warningText?: string } | undefined;
  onSave?: (date: Date | undefined) => void;
  dateProps?: Date | undefined;
  disabled?: Matcher | Matcher[] | undefined;
}) {
  const [open, setOpen] = useState(false);
  const [dateState, setDateState] = useState<Date | undefined>(dateProps || undefined);
  React.useEffect(() => {
    setDateState(dateProps || undefined)
  }, [dateProps])

  return (
    <div className={cn("grid gap-2", className)}>
      <Popover open={open} onOpenChange={(e) => setOpen(e)}>
        <PopoverTrigger asChild>
          <Button
            variant={"outline"}
            size="sm"
            className={cn(
              "justify-start text-left font-normal font-body",
              !dateState && "text-muted-foreground",
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {dateState ? (
              format(dateState, "dd.MM.Y", {
                locale: i18n.language === "de" ? de : enGB,
              })
            ) : (
              <span className="">{t("date_filter")}</span>
            )}
            {dateState && (
              <Badge variant="default" className="ml-2" onClick={(e) => {
                onSave && onSave(undefined);
                e.preventDefault();
                e.stopPropagation();
              }}>
                <Icons.close className="h-3 w-3" />
              </Badge>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="end">
          <div className="flex md:flex-row flex-col">
            <Calendar
              locale={ i18n.language === "de" ? de : enGB }
              initialFocus
              mode="single"
              defaultMonth={dateState}
              selected={dateState}
              onDayClick={ (date) => {
                setDateState(date);
                onSave && onSave(date);
                setOpen(false);
              } }
              disabled={disabled}
              numberOfMonths={1}
            />
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
}
