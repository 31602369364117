import { useQuery } from "@tanstack/react-query";

import { MedicalReport } from "@models/medical-report";
import * as MedicalReportService from "@services/medical-report.service";


const useGetMedicalReport = ({id, accountId}: {id?: string, accountId: string}): { data: MedicalReport | null, isPending: boolean } => {
  const { data, isPending } = useQuery({
    queryKey: ["MedicalReport", accountId, id],
    queryFn: () => id ? MedicalReportService.getMedicalReport(accountId, id) : null,
  });

  return {
    data: data || null,
    isPending,
  };
};

export default useGetMedicalReport;
