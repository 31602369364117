import { fetcherWithToken, post, postWithToken, removeWithToken } from "@lib/request";

import { Account } from "@models/account";
import { User } from "@models/user";
import { QueryListOptions, QueryListResponse } from "@services/base-query.service";
import { toQueryStrings } from "@utils/query-options.utils";

export const createUser = async (user: User, password: string): Promise<User | undefined> => {
  return await postWithToken<User>(
    `${import.meta.env.VITE_API_BASE}/users`,
    {
      user,
      password,
    },
  );
};

export const getUser = async (id: string): Promise<User | undefined> => {
  return await fetcherWithToken<User>(`${import.meta.env.VITE_API_BASE}/users/${id}`);
};

export const getUsersOfAccount = async (accountId: string, options?: QueryListOptions): Promise<QueryListResponse<Pick<User, 'id' | 'firstname' | 'lastname'>>> => {
  const queryOptions = toQueryStrings(options);
  return await fetcherWithToken<QueryListResponse<Pick<User, 'id' | 'firstname' | 'lastname'>>>(
    `${import.meta.env.VITE_API_BASE}/accounts/${accountId}/users/${queryOptions ? "?" + queryOptions : ""}`
  );
};

export const updateUserPassword = async (id: string, password: string): Promise<void> => {
  return await postWithToken<void>(
    `${import.meta.env.VITE_API_BASE}/users/${id}/password`,
    {
      password,
    }
  );
};

export const deleteUser = async (id: string): Promise<void> => {
  return await removeWithToken<void>(`${import.meta.env.VITE_API_BASE}/users/${id}`);
};

export const getAccountsOfUser = async (id: string, options?: QueryListOptions): Promise<QueryListResponse<Pick<Account, 'id' | 'name'>>> => {
  const queryOptions = toQueryStrings(options);
  return await fetcherWithToken<QueryListResponse<Pick<Account, 'id' | 'name'>>>(
    `${import.meta.env.VITE_API_BASE}/users/${id}/accounts/${queryOptions ? "?" + queryOptions : ""}`
  );
};

export const signIn = async ({email, password}: {email: string, password: string}): Promise<{token: string}> => {
  return await post<{token: string}>(`${import.meta.env.VITE_API_BASE}/login`, {email, password});
};
