import { QueryObserverResult, useQuery } from "@tanstack/react-query";

import { User } from "@models/user";
import * as UserService from "@services/user.service";
import { QueryListOptions } from "@services/base-query.service";


const useGetUsers = (accountId: string, options?: QueryListOptions): { data: Pick<User, "id" | "firstname" | "lastname">[], isPending: boolean, refetch: () => Promise<QueryObserverResult>, pagination?: {
  continuationToken?: string | undefined;
  hasMoreResults: boolean;
} | undefined } => {

  const { data: response, isPending, refetch } = useQuery({
    queryKey: ["GetUsers", accountId, options],
    queryFn: () => UserService.getUsersOfAccount(accountId, options),
  });

  return {
    data: response?.data || [],
    pagination: response?.pagination || undefined,
    isPending,
    refetch,
  };
};

export default useGetUsers;
