import { type ClassValue, clsx } from "clsx";
import { convertFromHTML } from "draft-convert";
import { ContentState } from "draft-js";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const convertHTMLToContentState = (html: string): ContentState => {
  const contentState = convertFromHTML({
    htmlToStyle: (nodeName, _node, currentStyle) => {
        if(nodeName === "mark"){
            return currentStyle.add("MARK");
        }else {
          return currentStyle;
      }
    },
    htmlToBlock: (nodeName, node) => {
     if (nodeName === 'mark') {
          return {
              type: 'mark',
              data: {
                id: node.dataset.id
              }  
          };
      }
  }
})(html);
  return contentState;
};