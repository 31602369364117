import { Account } from "@models/account";
import { User } from "@models/user";
import * as UserAccess from "@access/user.access";
import { QueryListOptions, QueryListResponse } from "./base-query.service";


export async function createUser(user: User, password: string): Promise<User | undefined> {
  return await UserAccess.createUser(user, password);
}

export async function getUser(
  id: string
): Promise<User | undefined> {
  return await UserAccess.getUser(id);
}

export const getUsersOfAccount = async (accountId: string, options?: QueryListOptions): Promise<QueryListResponse<Pick<User, 'id' | 'firstname' | 'lastname'>>> => {
  return await UserAccess.getUsersOfAccount(accountId, options);
}

export async function updateUserPassword(id: string, password: string): Promise<void> {
  return await UserAccess.updateUserPassword(id, password);
}

export async function deleteUser(id: string): Promise<void> {
  return await UserAccess.deleteUser(id);
}

export async function getAccountsOfUser(
  id: string,
  options?: QueryListOptions,
): Promise<QueryListResponse<Pick<Account, 'id' | 'name'>>> {
  return await UserAccess.getAccountsOfUser(id, options);
}

export async function signIn(
  options: {email: string, password: string}
):Promise<{token: string}> {
  return await UserAccess.signIn(options);
}
