import {getColumnCases} from "@components/dashboard/column-case";
import UploadFile from "@components/dashboard/components/upload-file";
import {DataTable} from "@components/table/data-table";
import useGetMedicalReportProcesses from "@hooks/useGetMedicalReportProcesses";
import {useOpsCodeStore} from "@store/opsCode.store";
import {useUserStore} from "@store/user.store";
import {Skeleton} from "@ui/skeleton";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
  MedicalReportProcess,
  MedicalReportProcessState,
} from "@models/medical-report-process";
import {Input} from "@ui/input";
import {SingleDatePicker} from "@ui/single-date-picker";
import {format} from "date-fns";
import {Button} from "@ui/button";
import useGetUsers from "@hooks/useGetUsers";
import { DropdownMenu, DropdownMenuCheckboxItem, DropdownMenuContent, DropdownMenuTrigger } from "@ui/dropdown-menu";
import { CaretSortIcon } from "@radix-ui/react-icons";
import { Badge } from "@ui/badge";
import { cn } from "@lib/utils";
import * as MedicalReportService from "@services/medical-report.service";
import ConfirmDialog from "@components/common/confirm-dialog";


const STATE_OPTIONS = [
  { key: 'draft', name: MedicalReportProcessState.DRAFT, states: [MedicalReportProcessState.DRAFT, MedicalReportProcessState.PREPROCESSING] },
  { key: 'ready', name: MedicalReportProcessState.READY, states: [MedicalReportProcessState.READY] },
  { key: 'in_progress', name: MedicalReportProcessState.IN_PROGRESS, states: [MedicalReportProcessState.IN_PROGRESS] },
  { key: 'completed', name: MedicalReportProcessState.COMPLETED, states: [MedicalReportProcessState.COMPLETED] },
  { key: 'error', name: MedicalReportProcessState.ERROR, states: [MedicalReportProcessState.ERROR] },
  { key: 'archived', name: MedicalReportProcessState.ARCHIVED, states: [MedicalReportProcessState.ARCHIVED] },
];
type ActionType = 'delete';

const DashboardPage = () => {
  const [searchString, setSearchString] = useState("");
  const [stateFilter, setStateFilter] = useState<string[]>(
    STATE_OPTIONS
      .filter(option => option.key !== 'archived')
      .map(option => option.key)
  );
  const [userFilter, setUserFilter] = useState<string[]>([]);
  const [dateFilter, setDateFilter] = useState<Date | undefined>(undefined);
  const [cases, setCases] = useState<MedicalReportProcess[]>([]);

  const { t } = useTranslation();

  const { activeAccount, user } = useUserStore();
  const { reset } = useOpsCodeStore();

  const { data: listOfUsers } = useGetUsers(activeAccount?.id as string);

  const { data: newCases, isPending, refetch } = useGetMedicalReportProcesses(
    activeAccount?.id as string,
    {
      // limit: 10,
      searchString: searchString || "",
      states:
        stateFilter.length
          ? STATE_OPTIONS.flatMap((state) => stateFilter.includes(state.key) ? state.states : [])
          : undefined,
      createdByIds: userFilter.length ? userFilter : undefined,
      createdAtDate: dateFilter ? format(dateFilter, "yyyy-MM-dd") : undefined,
    }
  );

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState<MedicalReportProcess | null>(null);
  const [actionType, setActionType] = useState<ActionType | null>(null);

  useEffect(() => {
    reset();
  }, []);

  useEffect(() => {
    setUserFilter(user?.id ? [user.id] : []);
  }, [user]);

  useEffect(() => {
    setCases(newCases);
  }, [newCases]);

  const handleSearchSelectedDates = (selectedDate: Date | undefined) => {
    setDateFilter(selectedDate);
  };

  const handleUploadFileFinished = () => {
    refetch();
  };

  const handleStateFilter = (stateKey?: string) => {
    let newStateFilter: string[] = [];
    if (stateKey) {
      if (stateKey === 'all') {
        newStateFilter = STATE_OPTIONS.map((state) => state.key);
      } else {
        newStateFilter = stateFilter.includes(stateKey) ? stateFilter.filter(x => x !== stateKey) : [...stateFilter, stateKey];
      }
    }
    setStateFilter(newStateFilter);
  };

  const handleUserFilter = (userId?: string) => {
    let newUserFilter: string[] = [];
    if (userId) {
      if (userId === 'all') {
        newUserFilter = listOfUsers.map((user) => user.id);
      } else {
        newUserFilter = userFilter.includes(userId) ? userFilter.filter(x => x !== userId) : [...userFilter, userId];
      }
    }
    setUserFilter(newUserFilter);
  };

  const handleActionClick = async (actionType: ActionType, process: MedicalReportProcess) => {
    setSelectedItem(process);
    setActionType(actionType);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedItem(null);
    setActionType(null);
  };

  const handleConfirmAction = async () => {
    if (selectedItem && actionType && activeAccount) {
      switch (actionType) {
        case 'delete':
          await MedicalReportService.deleteMedicalReportProcess(activeAccount.id, selectedItem.id);
          refetch();
          break;
        default:
          break;
      }
      handleDialogClose();
    }
  };

  const columnCases = getColumnCases(user, handleActionClick);

  const getDialogProps = () => {
    switch (actionType) {
      case 'delete':
        return {
          title: t('dashboard.dialog_delete_title'),
          description: t('dashboard.dialog_delete_description', { name: selectedItem?.name || '' }),
          actionLabel: t('global_delete-btn'),
        };
      default:
        return {
          title: '',
          description: '',
          actionLabel: '',
        };
    }
  };

  return (
    <div className="container pt-6 space-y-3">
      <ConfirmDialog
        open={openDialog}
        onClose={handleDialogClose}
        onConfirm={handleConfirmAction}
        {...getDialogProps()}
      />

      <h1 className="font-bold text-3xl text-primary">{t("Arztberichte")}</h1>
      <h2 className="font-bold text-xl text-primary/70">
        {activeAccount?.name}
      </h2>
      <UploadFile fileUploaded={handleUploadFileFinished} />
      <div className="flex flex-col sm:flex-row gap-4 justify-between">
      <div className="flex gap-2">
        <Input
          className="w-full sm:w-1/2"
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
          placeholder={t("search") as string}
        />

        <DropdownMenu modal={false}>
          <DropdownMenuTrigger asChild className="sm:w-[240px] w-full">
            <Button variant="outline" className={ cn("justify-between", !stateFilter.length && "text-muted-foreground")}>
              {t("state_filter")}
              {stateFilter.length > 0 && (
                <Badge variant="default" onClick={(e) => {
                  handleStateFilter();
                  e.preventDefault();
                  e.stopPropagation();
                }}>
                  {stateFilter.length}
                </Badge>
              )}
              <CaretSortIcon className="h-4 w-4 opacity-50" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-[var(--radix-dropdown-menu-trigger-width)]">
            {[{ key: 'all', name: '' }, ...STATE_OPTIONS].map((state) => {
              return (
                <DropdownMenuCheckboxItem
                  key={state.key}
                  checked={stateFilter.includes(state.key)}
                  onCheckedChange={() => handleStateFilter(state.key)}
                  onSelect={(e) => e.preventDefault()}
                >
                  { state.key === "all" ? t("all") : JSON.parse(t("statuses"))[state.name] }
                </DropdownMenuCheckboxItem>
              );
            })}
          </DropdownMenuContent>
        </DropdownMenu>

        {listOfUsers.length > 0 && (
          <DropdownMenu modal={false}>
            <DropdownMenuTrigger asChild className="sm:w-[240px] w-full">
              <Button variant="outline" className={ cn("justify-between", !userFilter.length && "text-muted-foreground") }>
                {t("user_filter")}
                {userFilter.length > 0 && (
                  <Badge variant="default" onClick={(e) => {
                    handleUserFilter();
                    e.preventDefault();
                    e.stopPropagation();
                  }}>
                    {userFilter.length}
                  </Badge>
                )}
                <CaretSortIcon className="h-4 w-4 opacity-50" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-[var(--radix-dropdown-menu-trigger-width)]">
              {[{ id: 'all' }, ...listOfUsers].map((user) => {
                return (
                  <DropdownMenuCheckboxItem
                    key={user.id}
                    checked={userFilter.includes(user.id)}
                    onCheckedChange={() => handleUserFilter(user.id)}
                    onSelect={(e) => e.preventDefault()}
                  >
                    { user.id === "all" ? t("all") : `${user.firstname} ${user.lastname}` }
                  </DropdownMenuCheckboxItem>
                );
              })}
            </DropdownMenuContent>
          </DropdownMenu>
        )}
        </div>
        <div className="flex items-center gap-2">
            <SingleDatePicker
              dateProps={dateFilter}
              onSave={handleSearchSelectedDates}
            />
          </div>
      </div>
      <div className="flex">
        {isPending && !cases.length ? (
          <div className="div relative w-full space-y-3">
            <Skeleton className="h-10 w-full" />
            <Skeleton className="h-10 w-full" />
            <Skeleton className="h-10 w-full" />
            <Skeleton className="h-10 w-full" />
            <Skeleton className="h-10 w-full" />
            <Skeleton className="h-10 w-full" />
            <Skeleton className="h-10 w-full" />
            <Skeleton className="h-10 w-full" />
            <Skeleton className="h-10 w-full" />
            <Skeleton className="h-10 w-full" />
            <Skeleton className="h-10 w-full" />
            <Skeleton className="h-10 w-full" />
            <Skeleton className="h-10 w-full" />
            <Skeleton className="h-10 w-full" />
          </div>
        ) : cases.length > 0 ? (
          <div className="w-full">
            <DataTable columns={columnCases} data={cases} />
          </div>
        ) : (
          <p>Keine Arztberichte gefunden</p>
        )}
      </div>
    </div>
  );
};

export default DashboardPage;
