import { Icons } from "@components/icons";
import NavigationLink from "@components/sidebar/nav-link-sidebar";
import { sections } from "@components/sidebar/sidebar";
import SidebarItem from "@components/sidebar/sidebar-item";
import { useWindowSize } from "@hooks/useWindowSize";
import { useSidebarStore } from "@store/sidebar.store";
import { Button } from "@ui/button";
import { Sheet, SheetContent, SheetOverlay, SheetPortal } from "@ui/sheet";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const MenuMobile = () => {
  const location = useLocation();
  const { showSidebar: sidebarToogleState, toggleSidebar } = useSidebarStore(
    (state) => state,
  );
  const handleCloseMenu = () => {
    // Wait for animation to finish
    setTimeout(() => {
      toggleSidebar("close");
    }, 1000);
  };
  useEffect(() => {
    handleCloseMenu();
  }, [location]);

  const { width } = useWindowSize();

  if (width && width >= 512) return null;

  return (
    <div>
      <div className="w-full fixed p-4 flex justify-between border-b bg-pink-200 z-50 ">
        <Link to="/">
          <Icons.pageFlip />
        </Link>
        <Button onClick={() => toggleSidebar()}>
          <Icons.menu className="w-6 h-6" />
        </Button>
      </div>
      <Sheet
        open={sidebarToogleState === "open" ? true : false}
        onOpenChange={(e) => {
          toggleSidebar(e === true ? "open" : "close");
        }}
      >
        <SheetPortal>
          <SheetOverlay className="backdrop-blur-none bg-transparent" />
          <SheetContent
            closedBtn={false}
            className="w-full top-[70px] h-[calc(100%-70px)] border-none"
          >
            <div className="flex flex-col justify-between h-full">
              <div className="flex flex-col space-y-10 my-6">
                <div className="flex flex-col space-y-2">
                  {sections.map(({ title, path, icon }) => (
                    <NavigationLink to={path} key={title}>
                      {({ active }) => {
                        return (
                          <SidebarItem
                            className="sidebar--item-mobile"
                            icon={icon}
                            header={title}
                            active={active}
                          />
                        );
                      }}
                    </NavigationLink>
                  ))}
                </div>
              </div>
            </div>
          </SheetContent>
        </SheetPortal>
      </Sheet>
    </div>
  );
};

export default MenuMobile;
