import {Table, TableBody, TableCell, TableRow} from "@ui/table";
import {Loader} from "@ui/loader";
import {useDialogStore} from "@store/dialog.store";
import InnerModelDialog from "./inner-dialog";
import {Button} from "@ui/button";
import {Icons} from "@components/icons";
import {OpsRubricKind} from "@models/ops-rubric";
import {useTranslation} from "react-i18next";
import {
  MedicalReportCode,
  MedicalReportCodeAddedBy,
  MedicalReportCodeState,
} from "@models/medical-report-process";
import {useLayoutEffect} from "react";
import ModifierDialog from "./modifier-dialog";
import { Tab } from "@pages/case-page-details";
import useGetOPSorICDClasses from "@hooks/useGetOPSorICDClasses";
import useGetOPSorICDClass from "@hooks/useGetOPSorICDClass";

const InnerDialog = ({
  code,
  handleProccessCase,
  activeTab
}:{
  code: string | null;
  handleProccessCase: (newOpsCode: MedicalReportCode) => void;
  activeTab: Tab;
}) => {
  const {t} = useTranslation();
  const {show, setHeader} = useDialogStore((state) => state);
  const {data: opsClassInfo} = useGetOPSorICDClass(code, activeTab);
  const {data: subClassesData, isPending: isSubClassesPending} = useGetOPSorICDClasses(code, activeTab);
  useLayoutEffect(()=>{
    if (opsClassInfo.class) {
      setHeader((
        <div>
          {opsClassInfo.class.code} {opsClassInfo.class.name}
        </div>
      ));
    }

    if (opsClassInfo.modifierClasses?.length) {
      return show({
        content: (
          <ModifierDialog
            activeTab={activeTab}
            // @ts-ignore
            opsCode={opsClassInfo.class}
            code={opsClassInfo.modifierClasses[0].modifier}
            handleProccessCase={handleProccessCase}
          />
        ),
        header: null,
      });
    } else if (opsClassInfo.class?.subClasses?.length === 0) {
      return show({
        content: (
          <InnerModelDialog
            activeTab={activeTab}
            handleProccessCase={handleProccessCase}
            code={opsClassInfo.class?.superClass || null}
          />
        ),
        header: null,
      })
    }
  }, [opsClassInfo])

  const listNote = opsClassInfo.class?.rubrics.filter(
    (rubric) => rubric.kind === OpsRubricKind.NOTE
  );
  const listInclusion = opsClassInfo.class?.rubrics.filter(
    (rubric) => rubric.kind === OpsRubricKind.INCLUSION
  );
  const listExclusion = opsClassInfo.class?.rubrics.filter(
    (rubric) => rubric.kind === OpsRubricKind.EXCLUSION
  );

  return (
    <div className="h-[80vh] overflow-y-scroll rounded-md flex flex-col">
      {opsClassInfo.class?.code && (
        <div
          className="absolute top-6 text-sm left-4 cursor-pointer"
          onClick={() => {
            show({
              content: (
                <InnerModelDialog
                  activeTab={activeTab}
                  handleProccessCase={handleProccessCase}
                  code={opsClassInfo.class?.superClass || null}
                />
              ),
              header: null,
            })
          }}
        >
          <Icons.chevronLeft />
        </div>
      )}

      {isSubClassesPending ? (
        <Loader className="w-full flex justify-center my-2 mx-auto" />
      ) : subClassesData.length > 0 ? (
        <>
          <div className="space-y-4 p-3">
            {listInclusion && listInclusion.length > 0 ? (
              <div>
                <div className="text-sm font-bold">
                  {t("inclusive")}
                </div>
                <ul className="list-disc list-inside">
                  {listInclusion?.map((rubric, i) => {
                    return (
                      <li className="text-sm opacity-75" key={i}>
                        {rubric.text}
                      </li>
                    );
                  })}
                </ul>
              </div>
            ) : null}
            {listExclusion && listExclusion.length > 0 ? (
              <div>
                <div className="text-sm font-bold">
                  {t("exclusive")}
                </div>
                <ul className="list-disc list-inside">
                  {listExclusion?.map((rubric, i) => {
                    return (
                      <li className="text-sm opacity-75" key={i}>
                        {rubric.text}
                      </li>
                    );
                  })}
                </ul>
              </div>
            ) : null}
            {listNote && listNote.length > 0 ? (
              <div>
                <div className="text-sm font-bold">
                  {t("note")}
                </div>
                <ul className="list-disc list-inside">
                  {listNote?.map((rubric, i) => {
                    return (
                      <li className="text-sm opacity-75" key={i}>
                        {rubric.text}
                      </li>
                    );
                  })}
                </ul>
              </div>
            ) : null}
            <Table className="text-[12px]">
              <TableBody>
                {subClassesData.map((code) => (
                  <TableRow
                    key={code.id}
                    onClick={
                      code.code &&
                      code.subClasses &&
                      code.subClasses.length > 0
                        ? () => {
                            show({
                              content: (
                                <InnerModelDialog
                                  activeTab={activeTab}
                                  handleProccessCase={handleProccessCase}
                                  code={code.code}
                                />
                              ),
                              header: null,
                            });
                          }
                        : code?.modifiedBy?.length
                        ? () => {
                            show({
                              content: (
                                <ModifierDialog
                                activeTab={activeTab}
                                opsCode={code}
                                  // @ts-ignore
                                  code={code.modifiedBy[0].code}
                                  handleProccessCase={handleProccessCase}
                                />
                              ),
                              header: null,
                            });
                          }
                        : undefined
                    }
                  >
                    <TableCell className={"font-medium w-32"}>
                      {code.id}
                    </TableCell>
                    <TableCell>{code.name}</TableCell>
                    {(code.code &&
                      code.subClasses &&
                      code.subClasses.length > 0) ||
                    code?.modifiedBy?.length ? (
                      <TableCell>
                        <Button variant={"outline"}>
                          <Icons.chevronRight className="w-4 h-4" />
                        </Button>
                      </TableCell>
                    ) : (
                      <TableCell>
                        <Button
                          variant="outline"
                          className="text-green-500"
                          onClick={() =>
                            handleProccessCase({
                              name: code.code,
                              codeId: code.id,
                              description: code.nameLong || code.name,
                              addedBy: MedicalReportCodeAddedBy.MANUAL,
                              status: MedicalReportCodeState.CHECKED,
                              linkedTexts: [],
                            })
                          }
                        >
                          <Icons.check />
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </>
      ) : (
        <div>Keine Daten gefunden</div>
      )}
    </div>
  );
};

export default InnerDialog;
