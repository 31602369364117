import SignInForm from "@components/login/sign-in.form";
import SignUpForm from "@components/login/sign-up.form";
// import * as Auth from "@services/auth.service";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export default function AuthenticationPage() {
	const location = useLocation();
	const [formState, setFormState] = useState<"sign-in" | "sign-up">();
	useEffect(() => {
		// Google Analytics
		setFormState(location.pathname.includes("sign-up") ? "sign-up" : "sign-in");
	}, [location]);
	const { t } = useTranslation();
	return (
		<>
			<div className="container relative h-screen flex items-center justify-center">
				<div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
					<div className="flex flex-col space-y-4 text-center">
						<h1 className="text-2xl font-semibold tracking-tight">
							{formState === "sign-up"
								? t("login.create_account_header")
								: t("login.login_account_header")}
						</h1>
					</div>
					{formState === "sign-in" ? <SignInForm /> : <SignUpForm />}
				</div>
			</div>
		</>
	);
}
