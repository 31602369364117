import {Dialog, DialogContent, DialogHeader, DialogTitle} from "@ui/dialog";
import {Dispatch, SetStateAction, useEffect, useState} from "react";
import Editor from "@components/editor";
import {Button} from "@ui/button";
import * as MedicalReportService from "@services/medical-report.service";
import {toast} from "sonner";
import {Icons} from "@components/icons";
import {useTranslation} from "react-i18next";
import {useUploadedFileStore} from "@store/uploaded-file.store";
import {useUserStore} from "@store/user.store";
import { Input } from "@ui/input";
import { extractReportId } from "@utils/string";

const UploadModal = ({
  open,
  setOpen,
  content,
  fileUploaded,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  content: string;
  fileUploaded?: () => void;
}) => {
  const {t} = useTranslation();
  const [isPending, setIsPending] = useState(false);
  const {fileName} = useUploadedFileStore();
  const [customKey, setCustomKey] = useState<string>();
  const {activeAccount} = useUserStore();

  useEffect(()=>{
    if(fileName) setCustomKey(extractReportId(fileName) || "")
  }, [fileName])

  const handleProccessCase = async () => {
    if(!customKey) return
    setIsPending(true);
    try {
      await MedicalReportService.createMedicalReport(
        activeAccount?.id as string,
        fileName,
        content,
        customKey
      );
      fileUploaded && fileUploaded();
      toast.success(t("import_successfull"));
    } catch (error) {
      toast.error(t("import_error"));
    } finally {
      setIsPending(false);
      setOpen(false);
    }
  };

  return (
    <Dialog
      open={open}
      onOpenChange={() => {
        setOpen(!open);
        window.location.reload();
      }}
    >
      <DialogContent className="">
        <DialogHeader>
          <DialogTitle>{t("personal_info_in_file")}</DialogTitle>
        </DialogHeader>
        <Input value={customKey} onChange={(e)=>setCustomKey(e.target.value)} className="w-64" placeholder={t("file_name") as string} />
        <div className="h-[80vh] overflow-y-scroll border p-3 rounded-md text-[13px] ">
          {content && <Editor uploadedText={content} />}
        </div>
        <div className="flex items-center justify-end">
          <span className="text-right mr-4 text-sm text-primary">
            {t("confirm_data_sens")}
          </span>
          <Button
            disabled={isPending}
            className=""
            onClick={handleProccessCase}
          >
            {t("confirm")}{" "}
            {isPending && (
              <Icons.spinner className="mx-2 h-4 w-4 animate-spin" />
            )}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default UploadModal;
