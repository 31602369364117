import { OpsModifier } from "@models/ops-modifier";
import { IcdModifier } from "@models/icd-modifier";
import { Tab } from "@pages/case-page-details";
import useGetOpsModifier from "./useGetOpsModifier";
import useGetIcdModifier from "./useGetIcdModifier";


const useGetOPSorICDModifier = (id: string | null, activeTab: Tab): { data: OpsModifier | IcdModifier | null, isPending: boolean } => {
    if (activeTab === "icdCodes") {
        return useGetIcdModifier(id);
    } else  {
        return useGetOpsModifier(id);
    }
};

export default useGetOPSorICDModifier;
