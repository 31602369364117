import { cn } from "@lib/utils";
import {
  Check,
  Consumable,
  Delivery,
  DeliveryTruck,
  Iconoir,
  Message,
  MessageText,
  Package,
  PageFlip,
  BinMinus,
RefreshDouble
} from "iconoir-react";
import {
  AlertTriangle,
  ArrowRight,
  Atom,
  Ban,
  FileEdit,
  CheckCircle,
  ChevronLeft,
  ChevronRight,
  ClipboardCheck,
  Clock,
  Copy,
  CreditCard,
  Facebook,
  File,
  FileText,
  HelpCircle,
  type Icon as LucideIcon,
  Image,
  Instagram,
  Laptop,
  Linkedin,
  Loader2,
  LucideProps,
  Mail,
  MapPin,
  Menu,
  Moon,
  MoreVertical,
  MoreVerticalIcon,
  Pen,
  Phone,
  Pizza,
  Plus,
  Search,
  Settings,
  SunMedium,
  Trash,
  Twitter,
  User,
  X,
  XCircle,
  Stars,
  PlusCircle
} from "lucide-react";

export type Icon = LucideIcon;

export const Icons = {
  iconAir: Iconoir,
  package: Package,
  fileEdit: FileEdit,
  bin: BinMinus,
  delivery: Delivery,
  deliveryTruck: DeliveryTruck,
  consumable: Consumable,
  pageFlip: PageFlip,
  message: Message,
  messageText: MessageText,
  medical: (props: LucideProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none" {...props}>
<rect x="2.59375" y="2.83203" width="28.6458" height="28.6458" rx="14.3229" fill="#F4EBFF"/>
<rect x="2.59375" y="2.83203" width="28.6458" height="28.6458" rx="14.3229" stroke="#F9F5FF" strokeWidth="4.29688"/>
<path d="M10.9487 18.6471L16.9166 21.631L22.8844 18.6471M16.9166 12.6792L10.9487 15.6631L16.9166 18.6471L22.8844 15.6631L16.9166 12.6792Z" stroke="#0154FF" strokeWidth="1.19358" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
  ),
  search: Search,
  pen: Pen,
  more: MoreVerticalIcon,
  close: X,
  menu: Menu,
  cancelled: XCircle,
  failed: Ban,
  pending: Clock,
  atom: Atom,
  spinner: Loader2,
  chevronLeft: ChevronLeft,
  chevronRight: ChevronRight,
  trash: Trash,
  post: FileText,
  page: File,
  media: Image,
  settings: Settings,
  billing: CreditCard,
  ellipsis: MoreVertical,
  add: Plus,
  warning: AlertTriangle,
  user: User,
  arrowRight: ArrowRight,
  help: HelpCircle,
  pizza: Pizza,
  twitter: Twitter,
  check: Check,
  copy: Copy,
  copyDone: ClipboardCheck,
  sun: SunMedium,
  moon: Moon,
  circleCheck: CheckCircle,
  star: Stars,
  plusCircle: PlusCircle,
  deleteRing: (props: LucideProps) => (<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
  <path d="M12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 10.8181 3.23279 9.64778 3.68508 8.55585C4.13738 7.46392 4.80031 6.47177 5.63604 5.63604C6.47177 4.80031 7.46392 4.13738 8.55585 3.68508C9.64778 3.23279 10.8181 3 12 3C13.1819 3 14.3522 3.23279 15.4442 3.68508C16.5361 4.13738 17.5282 4.80031 18.364 5.63604C19.1997 6.47177 19.8626 7.46392 20.3149 8.55585C20.7672 9.64778 21 10.8181 21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4441 20.3149C14.3522 20.7672 13.1819 21 12 21L12 21Z" stroke="#DD0D0D" strokeWidth="2" strokeLinecap="round"/>
  <path d="M9 9L15 15" stroke="#DD0D0D" strokeWidth="2" strokeLinecap="round"/>
  <path d="M15 9L9 15" stroke="#DD0D0D" strokeWidth="2" strokeLinecap="round"/>
  </svg>),
  checkRing: (props: LucideProps) => (<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
  <circle cx="12" cy="12" r="9" stroke="#2DAF41" strokeWidth="2"/>
  <path d="M8 12L11 15L16 9" stroke="#2DAF41" strokeWidth="2"/>
  </svg>),
  circleCheckFilled: (props: LucideProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      height="20"
      width="20"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
        clipRule="evenodd"
      ></path>
    </svg>
  ),
  laptop: Laptop,
  phone: Phone,
  mail: Mail,
  refresh: RefreshDouble,
  pin: MapPin,
  instagram: Instagram,
  linkedin: Linkedin,
  facebook: Facebook,
  google: (props: LucideProps) => (
    <svg role="img" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M12.48 10.92v3.28h7.84c-.24 1.84-.853 3.187-1.787 4.133-1.147 1.147-2.933 2.4-6.053 2.4-4.827 0-8.6-3.893-8.6-8.72s3.773-8.72 8.6-8.72c2.6 0 4.507 1.027 5.907 2.347l2.307-2.307C18.747 1.44 16.133 0 12.48 0 5.867 0 .307 5.387.307 12s5.56 12 12.173 12c3.573 0 6.267-1.173 8.373-3.36 2.16-2.16 2.84-5.213 2.84-7.667 0-.76-.053-1.467-.173-2.053H12.48z"
      />
    </svg>
  ),
  googleColor: (props: LucideProps) => (
    <svg viewBox="0 0 48 48" {...props}>
      <path
        fill="#FFC107"
        d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
      />
      <path
        fill="#FF3D00"
        d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
      />
      <path
        fill="#4CAF50"
        d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
      />
      <path
        fill="#1976D2"
        d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
      />
    </svg>
  ),
  apple: (props: LucideProps) => (
    <svg role="img" viewBox="0 0 24 24" {...props}>
      <path
        d="M12.152 6.896c-.948 0-2.415-1.078-3.96-1.04-2.04.027-3.91 1.183-4.961 3.014-2.117 3.675-.546 9.103 1.519 12.09 1.013 1.454 2.208 3.09 3.792 3.039 1.52-.065 2.09-.987 3.935-.987 1.831 0 2.35.987 3.96.948 1.637-.026 2.676-1.48 3.676-2.948 1.156-1.688 1.636-3.325 1.662-3.415-.039-.013-3.182-1.221-3.22-4.857-.026-3.04 2.48-4.494 2.597-4.559-1.429-2.09-3.623-2.324-4.39-2.376-2-.156-3.675 1.09-4.61 1.09zM15.53 3.83c.843-1.012 1.4-2.427 1.245-3.83-1.207.052-2.662.805-3.532 1.818-.78.896-1.454 2.338-1.273 3.714 1.338.104 2.715-.688 3.559-1.701"
        fill="currentColor"
      />
    </svg>
  ),
  paypal: (props: LucideProps) => (
    <svg role="img" viewBox="0 0 24 24" {...props}>
      <path
        d="M7.076 21.337H2.47a.641.641 0 0 1-.633-.74L4.944.901C5.026.382 5.474 0 5.998 0h7.46c2.57 0 4.578.543 5.69 1.81 1.01 1.15 1.304 2.42 1.012 4.287-.023.143-.047.288-.077.437-.983 5.05-4.349 6.797-8.647 6.797h-2.19c-.524 0-.968.382-1.05.9l-1.12 7.106zm14.146-14.42a3.35 3.35 0 0 0-.607-.541c-.013.076-.026.175-.041.254-.93 4.778-4.005 7.201-9.138 7.201h-2.19a.563.563 0 0 0-.556.479l-1.187 7.527h-.506l-.24 1.516a.56.56 0 0 0 .554.647h3.882c.46 0 .85-.334.922-.788.06-.26.76-4.852.816-5.09a.932.932 0 0 1 .923-.788h.58c3.76 0 6.705-1.528 7.565-5.946.36-1.847.174-3.388-.777-4.471z"
        fill="currentColor"
      />
    </svg>
  ),
  inquery: (props: LucideProps) => (
    <svg width="18" height="21" viewBox="0 0 18 21" {...props}>
      <path
        d="M3.98438 15H11.0156V17.0156H3.98438V15ZM3.98438 11.0156H14.0156V12.9844H3.98438V11.0156ZM3.98438 6.98438H14.0156V9H3.98438V6.98438ZM15.9844 3H11.8125C11.3906 1.82812 10.3125 0.984375 9 0.984375C7.6875 0.984375 6.60938 1.82812 6.1875 3H2.01562C1.875 3 1.73438 3 1.59375 3.04688C1.21875 3.14062 0.84375 3.32812 0.609375 3.60938C0.421875 3.75 0.28125 3.98438 0.140625 4.21875C0.046875 4.45312 0 4.73438 0 5.01562V18.9844C0 19.2656 0.046875 19.5469 0.140625 19.7812C0.28125 20.0156 0.421875 20.25 0.609375 20.4375C0.84375 20.6719 1.21875 20.9062 1.59375 20.9531C1.73438 21 1.875 21 2.01562 21H15.9844C17.1094 21 18 20.1094 18 18.9844V5.01562C18 3.89062 17.1094 3 15.9844 3ZM9 2.76562C9.42188 2.76562 9.75 3.09375 9.75 3.51562C9.75 3.89062 9.42188 4.26562 9 4.26562C8.57812 4.26562 8.25 3.89062 8.25 3.51562C8.25 3.09375 8.57812 2.76562 9 2.76562ZM15.9844 18.9844H2.01562V5.01562H15.9844V18.9844Z"
        fill="#838A9C"
      ></path>
    </svg>
  ),
  twofile: (props: LucideProps) => (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        d="M15.9844 0.984375H3.98438C2.90625 0.984375 2.01562 1.92188 2.01562 3V17.0156H3.98438V3H15.9844V0.984375ZM18.9844 5.01562H8.01562C6.89062 5.01562 6 5.90625 6 6.98438V21C6 22.0781 6.89062 23.0156 8.01562 23.0156H18.9844C20.1094 23.0156 21 22.0781 21 21V6.98438C21 5.90625 20.1094 5.01562 18.9844 5.01562ZM18.9844 21H8.01562V6.98438H18.9844V21Z"
        fill="#838A9C"
      ></path>
    </svg>
  ),
  fileChecked: (props: LucideProps) => (
    <svg
      width="18"
      height="21"
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15 9L13.5938 7.59375L6.98438 14.1562L4.40625 11.5781L3 12.9844L6.98438 17.0156L15 9ZM15.9844 3H11.8125C11.3906 1.82812 10.3125 0.984375 9 0.984375C7.6875 0.984375 6.60938 1.82812 6.1875 3H2.01562C1.875 3 1.73438 3 1.59375 3.04688C1.21875 3.14062 0.84375 3.32812 0.609375 3.60938C0.421875 3.75 0.28125 3.98438 0.140625 4.21875C0.046875 4.45312 0 4.73438 0 5.01562V18.9844C0 19.2656 0.046875 19.5469 0.140625 19.7812C0.28125 20.0156 0.421875 20.25 0.609375 20.4375C0.84375 20.6719 1.21875 20.9062 1.59375 20.9531C1.73438 21 1.875 21 2.01562 21H15.9844C17.1094 21 18 20.1094 18 18.9844V5.01562C18 3.89062 17.1094 3 15.9844 3ZM9 2.76562C9.42188 2.76562 9.75 3.09375 9.75 3.51562C9.75 3.89062 9.42188 4.26562 9 4.26562C8.57812 4.26562 8.25 3.89062 8.25 3.51562C8.25 3.09375 8.57812 2.76562 9 2.76562ZM15.9844 18.9844H2.01562V5.01562H15.9844V18.9844Z"
        fill="#838A9C"
      ></path>
    </svg>
  ),
  left: (props: LucideProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="1em"
      viewBox="0 0 512 512"
      className={cn("w-4, h-4", props.className)}
      {...props}
    >
      <path d="M256 504C119 504 8 393 8 256S119 8 256 8s248 111 248 248-111 248-248 248zM142.1 273l135.5 135.5c9.4 9.4 24.6 9.4 33.9 0l17-17c9.4-9.4 9.4-24.6 0-33.9L226.9 256l101.6-101.6c9.4-9.4 9.4-24.6 0-33.9l-17-17c-9.4-9.4-24.6-9.4-33.9 0L142.1 239c-9.4 9.4-9.4 24.6 0 34z" />
    </svg>
  ),
  right: (props: LucideProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      className={cn("w-4, h-4", props.className)}
      {...props}
    >
      <path d="M256 8c137 0 248 111 248 248S393 504 256 504 8 393 8 256 119 8 256 8zm113.9 231L234.4 103.5c-9.4-9.4-24.6-9.4-33.9 0l-17 17c-9.4 9.4-9.4 24.6 0 33.9L285.1 256 183.5 357.6c-9.4 9.4-9.4 24.6 0 33.9l17 17c9.4 9.4 24.6 9.4 33.9 0L369.9 273c9.4-9.4 9.4-24.6 0-34z" />
    </svg>
  ),
};
