import { MedicalReportCode } from "@models/medical-report-process";


/**
 * Searches the given text for all occurrences of strings in the linkedTexts list
 * of the MedicalReportCode objects and marks those occurrences.
 * 
 * @param text The text to search through.
 * @param code A MedicalReportCode object whose linkedTexts are to be searched in the text.
 * @returns The text with marked occurrences of the linkedTexts.
 */
export function highlightMedicalReportCode(text: string, code: MedicalReportCode): string {
  let highlightedText = text;
  code.linkedTexts?.forEach(linkedText => {
    const escapedText = escapeRegExp(linkedText.replace(/\n/g, '<br />'));
    const regex = new RegExp(`(${escapedText})`, 'gi');
    highlightedText = highlightedText.replace(regex, `<mark data-id="${code.codeId}">$1</mark>`);
  });

  return highlightedText;
}

/**
 * Escapes all characters in a string that could have a special meaning in a regular expression.
 * 
 * @param text The text to escape.
 * @returns The escaped text.
 */
function escapeRegExp(text: string): string {
  return text.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
}
