import NavigationLink from "@components/sidebar/nav-link-sidebar";
import { sections } from "@components/sidebar/sidebar";
import SidebarBadge from "@components/sidebar/sidebar-badge";
import SidebarItem from "@components/sidebar/sidebar-item";
import { cn } from "@lib/utils";
import React from "react";
import SidebarToggler from "./sidebar-toggler";
import { useSidebarStore } from "@store/sidebar.store";

const Menu = ({ className, ...props }: React.HTMLAttributes<HTMLElement>) => {
  const { showSidebar } = useSidebarStore((state) => state);

  return (
    <aside
    className={cn(
      "w-14 transition-[width] md:w-64 h-screen hidden sm:block relative",
      showSidebar === "close" && "sidebar-open",
      className
    )}
    {...props}
    >
      <SidebarBadge />
      <div className="flex flex-col justify-between h-[calc(100vh-150px)]">
        <div className="flex flex-col space-y-10 my-6">
          <div className="flex flex-col space-y-2">
            {sections.map(({ title, path, icon }) => (
              <NavigationLink to={path} key={title}>
                {({ active }) => {
                  return <SidebarItem icon={icon} header={title} active={active} />;
                }}
              </NavigationLink>
            ))}
          </div>
        </div>
      </div>
      <SidebarToggler />
    </aside>
  );
};

export default Menu;
