import { QueryObserverResult, useQuery } from "@tanstack/react-query";

import { MedicalReportProcess } from "@models/medical-report-process";
import * as MedicalReportService from "@services/medical-report.service";
import { ProcessQueryListOptions } from "@services/medical-report.service";


const useGetMedicalReportProcesses = (accountId: string, options?: ProcessQueryListOptions): { data: MedicalReportProcess[], isPending: boolean, refetch: () => Promise<QueryObserverResult>, pagination?: {
  continuationToken?: string | undefined;
  hasMoreResults: boolean;
} | undefined } => {

  const { data: response, isPending, refetch } = useQuery({
    queryKey: ["MedicalReportProcesses", accountId, options],
    queryFn: () => MedicalReportService.getMedicalReportProcesses(accountId, options),
    refetchInterval: 20000, // 1 minute
  });

  return {
    data: response?.data || [],
    pagination: response?.pagination || undefined,
    isPending,
    refetch,
  };
};

export default useGetMedicalReportProcesses;
