import "@src/styles/sidebar.css";

import { Icons } from "@components/icons";
import Menu from "./menu";
import MenuMobile from "./menu-mobile";
export interface SectionSteps {
  path: string;
  title: string;
  icon: JSX.Element;
}
export const sections: SectionSteps[] = [
  { title: "Arztberichte", path: "/", icon: <Icons.medical /> },
];

export function Sidebar() {
  return (
    <>
      <Menu />
      <MenuMobile />
    </>
  );
}
