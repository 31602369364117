import { Account } from "@models/account";
import { User } from "@models/user";
import { useUserStore } from "@store/user.store";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const useAuth = () => {
  const { setUser, user, setAccounts, setActiveAccount, setToken } = useUserStore();
  const navigate = useNavigate();

  useEffect(() => {
    const userFromStorage: User | undefined = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user") as string) : undefined;
    const accountsFromStorage: Account[] | undefined = localStorage.getItem("accounts") ? JSON.parse(localStorage.getItem("accounts") as string) : undefined;
    const activeAccountFromStorage: Account | undefined = localStorage.getItem("activeAccount") ? JSON.parse(localStorage.getItem("activeAccount") as string) : undefined;
    const tokenFromStorage: string | undefined = localStorage.getItem("token") ? localStorage.getItem("token") as string : undefined;
    if(userFromStorage && accountsFromStorage && activeAccountFromStorage && tokenFromStorage){
        setUser(userFromStorage);
        setAccounts(accountsFromStorage);
        setActiveAccount(activeAccountFromStorage);
        setToken(tokenFromStorage)
    }else{
        navigate("/sign-in");
    }
  }, [navigate]);

  return {
    user: user,
  };
};
