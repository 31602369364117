import { useQuery } from "@tanstack/react-query";

import { OpsClass } from "@models/ops-class";
import * as OpsService from "@services/ops.service";
import { QueryListOptions } from "@services/base-query.service";


const useGetOpsSubClasses = (id: string | null, options?: QueryListOptions): { data: OpsClass[], isPending: boolean } => {
  const { data: response, isPending } = useQuery({
    queryKey: ["OpsSubClasses", id, options],
    queryFn: () => OpsService.getOpsSubClasses(id, options),
  });

  return {
    data: response?.data || [],
    isPending,
  };
};

export default useGetOpsSubClasses;
