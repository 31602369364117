import { useQuery } from "@tanstack/react-query";

import { OpsClass } from "@models/ops-class";
import { OpsModifierClass } from "@models/ops-modifier";
import * as OpsService from "@services/ops.service";


const useGetOpsClass = (id: string | null): { data: { class?: OpsClass, modifierClasses?: OpsModifierClass[] }, isPending: boolean } => {
  const { data, isPending } = useQuery({
    queryKey: ["OpsClass", id],
    queryFn: () => id ? OpsService.getOpsClass(id) : null,
  });

  return {
    data: data || { class: undefined, modifierClasses: undefined },
    isPending,
  };
};

export default useGetOpsClass;
