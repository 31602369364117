import { create } from "zustand";

interface UploadedFileState {
  content?: string;
  fileName?: string;
  stringExists: boolean;
  setContent: (value?: string) => void;
  setFileName: (value?: string) => void;
  removeStringFromContent: (value: string) => void;
  restoreStringInContent: (value: string) => void;
  checkStringInContent: (value: string) => void;
}

export const useUploadedFileStore = create<UploadedFileState>()((set) => ({
  content: undefined,
  stringExists: false,
  setContent: (value) =>
    set(() => ({
      content: value
    })),
    setFileName: (value) =>
    set(() => ({
      fileName: value
    })),
  removeStringFromContent: (value) => {
    set((state) => {
      const content = state.content?.replace(new RegExp(value, 'g'), `<mark>${value}</mark>`);
      return { content: content };
    });
  },
  restoreStringInContent: (value) => {
    set((state) => {
      const content = state.content?.replace(new RegExp(`<mark>${value}</mark>`, 'g'), value);
      return { content: content };
    });
  },
  checkStringInContent: (value) => {
    set(( state )=> {
      const regex = new RegExp(`<mark>${value}</mark>`, 'g');
      return {...state, stringExists: !!state.content?.match(regex) };
    })
  }
}));
