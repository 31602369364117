import { cn } from "@lib/utils";
import {
  ColumnDef,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  Table as TableType,
  useReactTable,
  VisibilityState,
} from "@tanstack/react-table";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@ui/table";
import * as React from "react";
import { useTranslation } from "react-i18next";

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  dataTableToolbar?: (props: { table: TableType<TData> }) => JSX.Element;
  renderSubComponent?: (props: { row: Row<TData> }) => React.ReactElement | null;
  renderRegularSubComponent?: (props: { row: Row<TData> }) => React.ReactElement | null;
  getRowCanExpand?: (row: Row<TData>) => boolean;
  ignoreGetRowCanExpand?: boolean;
  className?: string;
}

export function DataTable<TData, TValue>({
  columns,
  data,
  dataTableToolbar,
  renderSubComponent,
  renderRegularSubComponent,
  ignoreGetRowCanExpand = false,
  getRowCanExpand = () => false,
  className,
}: DataTableProps<TData, TValue>) {
  const { t } = useTranslation();
  const [rowSelection, setRowSelection] = React.useState({});
  const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({});
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters,
    },
    enableRowSelection: true,
    getRowCanExpand,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  return (
    <div className="space-y-4 relative">
      {dataTableToolbar && dataTableToolbar({ table })}
      <div className={cn(className)}>
        <Table className="border-separate border-spacing-y-4 relative">
          <TableHeader className="sticky sticky-header-table z-20 rounded-lg shadow-sm">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id} className="">
                {headerGroup.headers.map((header, index) => {
                  return (
                    <TableHead
                      key={header.id}
                      className={cn(
                        "bg-secondary p-4",
                        headerGroup.headers.length - 1 === index
                          ? "sticky right-0 bg-secondary rounded-br-xl"
                          : "",
                        index === 0 ? "rounded-bl-xl" : "",
                      )}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => {
                return (
                  <React.Fragment key={row.id}>
                    <TableRow
                      data-state={row.getIsSelected() && "selected"}
                      className="rounded-3xl border-b hover:border-1 hover:border-primary relative"
                    >
                      {row.getVisibleCells().map((cell, index) => {
                        if (cell.column.id === "link") {
                          return (
                            <TableCell
                              key={cell.id}
                              className={cn(
                                "p-0 border-b border-t group-hover:border-1 group-hover:border-primary",
                                row.getVisibleCells().length - 1 === index
                                  ? "sticky-cell rounded-r-xl border-r"
                                  : "",
                                index === 0 ? "rounded-l-xl border-l" : "",
                              )}
                            >
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </TableCell>
                          );
                        }

                        return (
                          <TableCell
                            key={cell.id}
                            className={cn(
                              "p-4 border-b border-t group-hover:border-1 group-hover:border-primary",
                              row.getVisibleCells().length - 1 === index
                                ? "sticky-cell rounded-r-xl border-r"
                                : "",
                              index === 0 ? "rounded-l-xl border-l" : "",
                            )}
                          >
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                    {renderSubComponent && row.getIsExpanded() && (
                      <TableRow>{renderSubComponent({ row })}</TableRow>
                    )}
                    {renderRegularSubComponent &&
                      (row.getIsExpanded() || ignoreGetRowCanExpand) &&
                      renderRegularSubComponent({ row })}
                  </React.Fragment>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  {t("dashboard-no_results")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}