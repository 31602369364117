import { useDialogStore } from "@store/dialog.store";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@ui/dialog";
export default function Sheet() {
  const { showDialog, triggerDialog, content, header } = useDialogStore((state) => state);
  return (
    <>
      <Dialog open={showDialog} onOpenChange={() => triggerDialog(false)}>
      <DialogContent className="!max-w-2xl">
        <DialogHeader className="ml-6">
            {header}
          <DialogTitle>
          </DialogTitle>
        </DialogHeader>
        {content}
        </DialogContent>
        </Dialog>
    </>
  );
}
