import {create} from "zustand";

interface OpsCodeStore {
  store: string[];
  addCode: (value: string | string[]) => void;
  removeOpsCode: (value: string) => void;
  reset: () => void;
}

export const useOpsCodeStore = create<OpsCodeStore>()((set) => ({
  store: [],
  addCode: (value) =>
    set((state) => {
      if(typeof value === "string") {
        return {
          store: [...state.store, value],
        };
      }
      return {
        store: [...state.store, ...value],
      };
    }),
  removeOpsCode: (value) =>
    set((state) => {
      return {
        store: [...state.store.filter((ops) => !ops.includes(value))],
      };
    }),
  reset: () =>
    set(() => ({
      store: [],
    })),
}));
