import { Account } from "@models/account";
import { User } from "@models/user";
import { create } from "zustand";

interface UserState {
  user?: User | null;
  activeAccount: null | Account;
  accounts: Account[] | null;
  token: string | null;
  setUser: (user: User | null) => void;
  setToken: (token: string | null) => void;
  setAccounts: (accounts: Account[] | null) => void;
  setActiveAccount: (account: Account | null) => void;
}

export const useUserStore = create<UserState>()((set) => ({
  user: null,
  activeAccount: null,
  accounts: null,
  token: null,
  setToken: (token) =>{
    localStorage.setItem("token", token as string);
    set(() => ({
      token,
    }));
  },
  setUser: (user) =>{
    localStorage.setItem("user", JSON.stringify(user));
    set(() => ({
      user,
    }));
  },
  setAccounts: (accounts) =>{
    localStorage.setItem("accounts", JSON.stringify(accounts));
    set(() => ({
      accounts,
    }));
  },
  setActiveAccount: (account) =>{
    localStorage.setItem("activeAccount", JSON.stringify(account));
    set(() => ({
      activeAccount: account,
    }));
  },
}));
