export function removeStringsAfterTheWord(content: string, word: string): string {
    const words = content.trim().split(/\s+/);
  
    for (let i = 0; i < words.length; i++) {
      if (words[i] === word) {
        // Check if there are at least two words after 'word'
        if (i + 2 < words.length) {
          // Remove the next two words after 'word'
          words.splice(i + 1, 2);
        } else {
          // Handle case where there are not enough words after 'word'
          console.warn("Not enough words after 'Herr' to remove.");
        }
      }
    }
  
    // Join the words back into a string
    const result = words.join(' ');
    return result;
  }
  


  export function findTextAfterTheFollowingAndRemove(textContent: string, targetString: string): string {
    // Split the text content into an array of words
    const words = textContent.split(/\s+/);

    // Iterate through the words to find the target string
    for (let i = 0; i < words.length; i++) {
        if (words[i] === targetString) {
            console.log("Found target string: " + targetString);
            // Remove the next two words if there are enough words remaining
            if (i + 2 < words.length) {
                console.log("Removing next two words: " + words[i + 1] + " " + words[i + 2]);
                words.splice(i + 1, 2);
            }
        }
    }

    // Join the remaining words back into a string
    const result = words.join(' ');

    return result;
}


export function extractReportId(filename: string): string | undefined {
  // Expand the regular expression to include the "S_" prefix and extract it completely
  const match = /(S_\d+|\d+).*/.exec(filename);
  if (match) {
      return match[1]; // Extracts the complete report number, including "S_" if available
  }
  return undefined;
}