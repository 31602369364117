import {ToolbarChildrenProps} from "@draft-js-plugins/inline-toolbar/lib/components/Toolbar";
import {useUploadedFileStore} from "@store/uploaded-file.store";
import {Button} from "@ui/button";
import {useEffect} from "react";

const RemoveButtonToolbar: React.FC<ToolbarChildrenProps> = (props) => {
  const onMouseDown = (event: React.MouseEvent) => event.preventDefault();
  const {removeStringFromContent, restoreStringInContent, stringExists, content, checkStringInContent} =
  useUploadedFileStore();
  const selection = props.getEditorState().getSelection();
  const startKey = selection.getStartKey();
  const startOffset = selection.getStartOffset();
  const endOffset = selection.getEndOffset();
  const contentState = props.getEditorState().getCurrentContent();
  const selectedText = contentState
    .getBlockForKey(startKey)
    .getText()
    .slice(startOffset, endOffset)
    .trim();
    useEffect(()=>{
      if(!selectedText) return;
      checkStringInContent(selectedText);
    }, [selectedText, content ])

  const onClick = (restore: boolean) => {
    const selection = props.getEditorState().getSelection();
    const startKey = selection.getStartKey();
    const startOffset = selection.getStartOffset();
    const endOffset = selection.getEndOffset();
    const contentState = props.getEditorState().getCurrentContent();
    const selectedText = contentState
      .getBlockForKey(startKey)
      .getText()
      .slice(startOffset, endOffset)
      .trim();
    if (restore) {
      restoreStringInContent(selectedText);
    } else {
      removeStringFromContent(selectedText);
    }
  };

  return (
    <div onMouseDown={onMouseDown} className="inline-block">
      {<Button
        className="border-none"
        onClick={() => onClick(stringExists)}
        variant={"outline"}
      >
        {stringExists ? "Restore" : "Remove"}
      </Button>}
    </div>
  );
};

export default RemoveButtonToolbar;
