import { BaseModel } from "./base-model";

export enum MedicalReportProcessState {
  /** MedicalReportProcess imported by external process. */
  IMPORTING = 'importing',
  /** MedicalReportProcess ready to be preprocessed. */
  DRAFT = 'draft',
  /** MedicalReportProcess in preprocessing by AI. */
  PREPROCESSING = 'preprocessing',
  /** MedicalReportProcess preprocessed by AI and ready to be reviewed by human. */
  READY = 'ready',
  /** Review of the MedicalReportProcess started by human. */
  IN_PROGRESS = 'in_progress',
  /** Review of the MedicalReportProcess completed by human. */
  COMPLETED = 'completed',
  /** MedicalReportProcess deleted. */
  DELETED = 'deleted',
  /** Error occurred. */
  ERROR = "error",

  ARCHIVED = "archived",
}

export enum MedicalReportCodeState {
  UNCHECKED = 'unchecked',
  CHECKED = 'checked',
  REJECTED = 'rejected',
}

export enum MedicalReportCodeAddedBy {
  PLATFORM = 'platform',
  MANUAL = 'manual',
}

export interface MedicalReportCode {
  codeId: string;
  name: string;
  description: string;
  addedBy: MedicalReportCodeAddedBy;
  status: MedicalReportCodeState;
  linkedTexts?: string[];
  tags?: string[];
}

export const MedicalReportProcessType = "medical_report_process";

/**
 * The MedicalReportProcess object.
 */
export interface MedicalReportProcess extends BaseModel {
  type: typeof MedicalReportProcessType;

  /** The Account this object belongs to. */
  accountId: string;

  processor: string;
  /** Process time in seconds. */
  processTime?: number;

  createdBy: string;
  createdByName: string;
  editedBy: string;
  editedByName: string;

  name: string;
  description?: string;
  state: MedicalReportProcessState;

  medicalReportId: string;
  icdCodes?: MedicalReportCode[];
  opsCodes?: MedicalReportCode[];

  tags?: string[];
}
