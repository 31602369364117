import translationDe from "@assets/locale/de.json"
import translationEn from "@assets/locale/en.json"
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend, { HttpBackendOptions } from "i18next-http-backend";
import { initReactI18next } from "react-i18next";



export const languages = [
  { key: "en", label: "English" },
  { key: "de", label: "Deutsch" },
];

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init<HttpBackendOptions>({
    fallbackLng: "en",
    debug: false,
    resources: {
      en: { translation: translationEn },
      de: { translation: translationDe },
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
