import {Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle} from "@ui/dialog";
import {Button} from "@ui/button";
import {useTranslation} from "react-i18next";


const ConfirmDialog = ({
  open,
  onClose,
  onConfirm,
  title,
  description,
  actionLabel,
}: {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  description: string;
  actionLabel: string;
}) => {
  const {t} = useTranslation();

  return (
    <Dialog
      open={open}
      onOpenChange={onClose}
    >
      <DialogContent className="">
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        <DialogDescription>{description}</DialogDescription>
        </DialogHeader>
          
        
        <div className="flex items-center justify-end">
          <Button variant={"outline"} onClick={onClose} style={{ marginRight: 10 }}>
            {t("global_cancel-btn")}
          </Button>
          <Button onClick={onConfirm}>{actionLabel}</Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmDialog;
