import {getStateVariant} from "@components/dashboard/components/CaseItem";
import {Icons} from "@components/icons";
import useGetMedicalReport from "@hooks/useGetMedicalReport";
import useGetMedicalReportProcess from "@hooks/useGetMedicalReportProcess";
import {cn} from "@lib/utils";
import {
  MedicalReportCode,
  MedicalReportCodeAddedBy,
  MedicalReportCodeState,
  MedicalReportProcessState,
} from "@models/medical-report-process";
import {DotsHorizontalIcon} from "@radix-ui/react-icons";
import {useDialogStore} from "@store/dialog.store";
import {Badge} from "@ui/badge";
import {Button} from "@ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@ui/dropdown-menu";
import {Skeleton} from "@ui/skeleton";
import {format} from "date-fns";
import {useTranslation} from "react-i18next";
import {Link, useParams} from "react-router-dom";
import * as MedicalService from "@services/medical-report.service";
import {useEffect, useRef, useState} from "react";
import PageDetailsEditor from "@components/case-page-details/editor";
import {useOpsCodeStore} from "@store/opsCode.store";
import {highlightMedicalReportCode} from "@utils/medical-report.utils";
import {ScrollArea} from "@ui/scroll-area";
import {useUserStore} from "@store/user.store";
import useGetUserId from "@hooks/useGetUserId";
import InnerDialog from "@components/search-modal/inner-dialog";
import {Tabs, TabsContent, TabsList, TabsTrigger} from "@ui/tabs";
import SearchForm from "@components/case-page-details/search.form";
import {CodeSearchResult} from "@models/code-search-result";
import { calculateMedicalReportMetrics } from "@utils/metrics.utils";


export type Tab = "icdCodes" | "opsCodes";

const CasePageDetails = () => {
  const {t} = useTranslation("");
  const [isSubmitPending, setIsSubmitPending] = useState(false);
  const [activeTab, setActiveTab] = useState<Tab>("opsCodes");
  const [reportContentString, setReportContentString] = useState("");
  const {
    store: codeSentenceStore,
    reset,
    removeOpsCode,
    addCode,
  } = useOpsCodeStore();
  const [highlightMedicalReportCodeState, setHighlightMedicalReportCodeState] =
    useState<MedicalReportCode | undefined>(undefined);
  const scrollRef = useRef<any>();
  const [searchTextState, setSearchTextState] = useState("");
  const { activeAccount, user } = useUserStore();
  const {show, triggerDialog} = useDialogStore((state) => state);
  const params = useParams();
  const {
    data: reportProcess,
    isPending,
    refetch,
  } = useGetMedicalReportProcess(
    params?.id as string,
    activeAccount?.id as string
  );

  const {data: report} = useGetMedicalReport({
    id: reportProcess?.medicalReportId,
    accountId: activeAccount?.id as string,
  });
  const {data: userInfo} = useGetUserId(reportProcess?.createdBy as string);

  useEffect(() => {
    setReportContentString(report?.contentString || "");
  }, [report]);

  // Function to scroll to a specific element by ID
  const findCodeHandler = (codeName: string | undefined) => {
    reset();
    if (!codeName) return;

    let text = report?.contentString || "";
    const codeObject = activeTab === "opsCodes"
      ? reportProcess?.opsCodes?.find((code) => code.name === codeName)
      : reportProcess?.icdCodes?.find((code) => code.name === codeName);
    if (codeObject) {
      text = highlightMedicalReportCode(text, codeObject);
      if (codeObject.linkedTexts && codeObject.linkedTexts?.length > 0) {
        addCode(codeObject.linkedTexts);
        setHighlightMedicalReportCodeState(codeObject);
      }
    }

    setReportContentString(text);

    // TODO: instead looking for all span elements it would be sufficient to look for the marked elements only
    // but rendering of sub component (triggered by setReportContentString()) has to be finished first
    const elements: NodeListOf<Element> = document.querySelectorAll(
      'span[data-text="true"]'
    );

    // use longest part to ensure finding right element
    const searchText = codeObject?.linkedTexts?.length
      ? codeObject.linkedTexts[0]
        .split("\n")
        .reduce((longest, currentLine) => {
          return currentLine.length > longest.length ? currentLine : longest;
        }, "")
      : undefined;

    let scrolled = false;

    if (elements.length <= 0 || !searchText) return;
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].textContent?.includes(searchText)) {
        if (!scrolled) {
          elements[i].scrollIntoView({behavior: "smooth"});
          scrolled = true;
        }
      }
    }
  };

  const handleSelectedSearchResult = async (code: CodeSearchResult) => {
    if (!code) return;
    const medicalReport: {
      opsCodes: MedicalReportCode[];
      icdCodes: MedicalReportCode[];
    } = {
      opsCodes: reportProcess?.opsCodes || [],
      icdCodes: reportProcess?.icdCodes || [],
    };
    if (activeTab === "opsCodes") {
      medicalReport.opsCodes = [
        ...(reportProcess?.opsCodes || []),
        {
          name: code.code,
          codeId: code.code,
          description: code.nameLong,
          addedBy: MedicalReportCodeAddedBy.MANUAL,
          status: MedicalReportCodeState.CHECKED,
          linkedTexts: [],
        },
      ];
    } else {
      medicalReport.icdCodes = [
        ...(reportProcess?.icdCodes || []),
        {
          name: code.code,
          codeId: code.code,
          description: code.nameLong,
          addedBy: MedicalReportCodeAddedBy.MANUAL,
          status: MedicalReportCodeState.CHECKED,
          linkedTexts: [],
        },
      ];
    }
    if (code.isCodable) {
      await MedicalService.updateMedicalReportProcessCodes(
        activeAccount?.id as string,
        reportProcess?.id as string,
        {...medicalReport}
      );
      await refetch();
      return;
    } else {
      show({
        header: <div className="capitalize"></div>,
        content: (
          <InnerDialog
            activeTab={activeTab}
            handleProccessCase={async (newOpsCode: MedicalReportCode) => {
              if (activeTab === "opsCodes") {
                medicalReport.opsCodes = [
                  ...(reportProcess?.opsCodes || []),
                  newOpsCode,
                ];
              } else {
                medicalReport.icdCodes = [
                  ...(reportProcess?.icdCodes || []),
                  newOpsCode,
                ];
              }
              await MedicalService.updateMedicalReportProcessCodes(
                activeAccount?.id as string,
                reportProcess?.id as string,
                {
                  state: MedicalReportProcessState.IN_PROGRESS,
                  ...medicalReport,
                }
              );
              await refetch();
              triggerDialog(false);
            }}
            code={code.code}
          />
        ),
      });
    }
  };
  const actionForOpsCodeHandler = async (
    opsCode: MedicalReportCode | null,
    action: "confirm" | "delete" | "switch"
  ) => {
    // todo: confirm the following OPS code the for this case
    // Not sure how the adding or removing work. is it only on the FE and we do a bulk or one by one
    if (!opsCode) {
      show({
        header: <div className="capitalize"></div>,
        content: (
          <InnerDialog
            activeTab={activeTab}
            handleProccessCase={async (newOpsCode: MedicalReportCode) => {
              const medicalReport: {
                opsCodes: MedicalReportCode[];
                icdCodes: MedicalReportCode[];
              } = {
                opsCodes: reportProcess?.opsCodes || [],
                icdCodes: reportProcess?.icdCodes || [],
              };
              if (activeTab === "opsCodes") {
                medicalReport.opsCodes = [
                  ...(reportProcess?.opsCodes || []),
                  {
                    name: newOpsCode.name,
                    codeId: newOpsCode.codeId,
                    description: newOpsCode.description,
                    addedBy: MedicalReportCodeAddedBy.MANUAL,
                    status: MedicalReportCodeState.CHECKED,
                    linkedTexts: [],
                  },
                ];
              } else {
                medicalReport.icdCodes = [
                  ...(reportProcess?.icdCodes || []),
                  {
                    name: newOpsCode.name,
                    codeId: newOpsCode.codeId,
                    description: newOpsCode.description,
                    addedBy: MedicalReportCodeAddedBy.MANUAL,
                    status: MedicalReportCodeState.CHECKED,
                    linkedTexts: [],
                  },
                ];
              }
              await MedicalService.updateMedicalReportProcessCodes(
                activeAccount?.id as string,
                reportProcess?.id as string,
                {
                  state: MedicalReportProcessState.IN_PROGRESS,
                  ...medicalReport,
                }
              );
              await refetch();
              triggerDialog(false);
            }}
            code={
              null
              //  null activeTab === "opsCodes" ? (reportProcess?.opsCodes?.[0]
              //     ? reportProcess.opsCodes[0].name.split("-")[0] : null)
              //     :
            }
          />
        ),
      });
      return;
    }
    const medicalReport: {
      opsCodes: MedicalReportCode[];
      icdCodes: MedicalReportCode[];
    } = {
      opsCodes: reportProcess?.opsCodes || [],
      icdCodes: reportProcess?.icdCodes || [],
    };
    const allOpsCodes =
      activeTab === "opsCodes"
        ? reportProcess?.opsCodes?.filter(
            (opsCodeState) => opsCodeState.codeId !== opsCode.codeId
          ) || []
        : reportProcess?.icdCodes?.filter(
            (opsCodeState) => opsCodeState.codeId !== opsCode.codeId
          ) || [];

    //If PLATFORM and delete then change code state to REJECTED
    if (
      opsCode.addedBy === MedicalReportCodeAddedBy.PLATFORM &&
      action === "delete"
    ) {
      opsCode.status = MedicalReportCodeState.REJECTED;
      await MedicalService.updateMedicalReportProcessCodes(
        activeAccount?.id as string,
        reportProcess?.id as string,
        {
          state: MedicalReportProcessState.IN_PROGRESS,
          ...medicalReport,
          [activeTab === "opsCodes" ? "opsCodes" : "icdCodes"]: [
            ...allOpsCodes,
            opsCode,
          ],
        }
      );
      await refetch();
      return;
    }
    //    * If MANUAL and delete, then remove from code list.
    else if (
      opsCode.addedBy === MedicalReportCodeAddedBy.MANUAL &&
      action === "delete"
    ) {
      await MedicalService.updateMedicalReportProcessCodes(
        activeAccount?.id as string,
        reportProcess?.id as string,
        {
          state: MedicalReportProcessState.IN_PROGRESS,
          ...medicalReport,
          [activeTab === "opsCodes" ? "opsCodes" : "icdCodes"]: allOpsCodes,
        }
      );
      await refetch();
      return;
    }
    // If PLATFORM and SWITCH, then REJECT the old one and add a new code.
    else if (
      opsCode.addedBy === MedicalReportCodeAddedBy.PLATFORM &&
      action === "switch"
    ) {
      show({
        header: <div className="capitalize"></div>,
        content: (
          <InnerDialog
            activeTab={activeTab}
            handleProccessCase={async (newOpsCode: MedicalReportCode) => {
              opsCode.status = MedicalReportCodeState.REJECTED;
              await MedicalService.updateMedicalReportProcessCodes(
                activeAccount?.id as string,
                reportProcess?.id as string,
                {
                  state: MedicalReportProcessState.IN_PROGRESS,
                  ...medicalReport,
                  [activeTab === "opsCodes" ? "opsCodes" : "icdCodes"]: [
                    ...allOpsCodes,
                    opsCode,
                    newOpsCode,
                  ],
                }
              );
              await refetch();
              triggerDialog(false);
            }}
            code={opsCode.name || null}
          />
        ),
      });
      return;
    } // I If MANUAL and SWITCH, then replace
    else if (
      opsCode.addedBy === MedicalReportCodeAddedBy.MANUAL &&
      action === "switch"
    ) {
      show({
        header: <div className="capitalize"></div>,
        content: (
          <InnerDialog
            activeTab={activeTab}
            handleProccessCase={async (newOpsCode: MedicalReportCode) => {
              await MedicalService.updateMedicalReportProcessCodes(
                activeAccount?.id as string,
                reportProcess?.id as string,
                {
                  state: MedicalReportProcessState.IN_PROGRESS,
                  ...medicalReport,
                  [activeTab === "opsCodes" ? "opsCodes" : "icdCodes"]: [
                    ...allOpsCodes,
                    newOpsCode,
                  ],
                }
              );
              await refetch();
              triggerDialog(false);
            }}
            code={opsCode.name || null}
          />
        ),
      });
    } else if (action === "confirm") {
      opsCode.status = MedicalReportCodeState.CHECKED;
      await MedicalService.updateMedicalReportProcessCodes(
        activeAccount?.id as string,
        reportProcess?.id as string,
        {
          state: MedicalReportProcessState.IN_PROGRESS,
          ...medicalReport,
          [activeTab === "opsCodes" ? "opsCodes" : "icdCodes"]: [
            ...allOpsCodes,
            opsCode,
          ],
        }
      );
      await refetch();
      return;
    }
  };
  if (isPending)
    return (
      <div className="grid sm:grid-cols-2 grid-cols-1 relative w-full h-full m-2 gap-2">
        <div className="my-2">
          <Skeleton className="h-20 mb-2 w-full" />
          <Skeleton className="h-[800px] w-full" />
        </div>
        <div className="my-2">
          <Skeleton className="h-20 mb-2 w-full" />
          <Skeleton className="h-40 w-full mb-2" />
          <div className="space-y-2">
            <Skeleton className="h-10 w-full" />
            <Skeleton className="h-10 w-full" />
            <Skeleton className="h-10 w-full" />
            <Skeleton className="h-10 w-full" />
            <Skeleton className="h-10 w-full" />
            <Skeleton className="h-10 w-full" />
          </div>
        </div>
      </div>
    );
  // check the icdCodes and opsCodes
  const allOpsCodesCHECKED = reportProcess?.opsCodes?.every(
    (opsCode) => opsCode.status !== MedicalReportCodeState.UNCHECKED
  );
  // check the icdCodes and opsCodes
  const allIcdCodesCHECKED = reportProcess?.icdCodes?.every(
    (opsCode) => opsCode.status !== MedicalReportCodeState.UNCHECKED
  );

  const handleAddCodeToList = async () => {
    const medicalReport: {
      opsCodes: MedicalReportCode[];
      icdCodes: MedicalReportCode[];
    } = {
      opsCodes: reportProcess?.opsCodes || [],
      icdCodes: reportProcess?.icdCodes || [],
    };
    // For the higlighted code we need we just call API if not then we open the DIALOG
    if (highlightMedicalReportCodeState?.name) {
      const filteredCodes = activeTab === "icdCodes"
        ? reportProcess?.icdCodes?.filter(
            (icdCode) =>
              icdCode.name !== highlightMedicalReportCodeState?.name
          ) || []
        : reportProcess?.opsCodes?.filter(
            (opsCode) =>
              opsCode.name !== highlightMedicalReportCodeState?.name
          ) || [];
 
      await MedicalService.updateMedicalReportProcessCodes(
        activeAccount?.id as string,
        reportProcess?.id as string,
        {
          state: MedicalReportProcessState.IN_PROGRESS,
          ...medicalReport,
          [activeTab === "opsCodes" ? "opsCodes" : "icdCodes"]: [
            ...(filteredCodes),
            {...highlightMedicalReportCodeState, linkedTexts: codeSentenceStore},
          ],
        }
      );
      await refetch();
      reset();
      setHighlightMedicalReportCodeState(undefined);
      return;
    }
    show({
      header: <div className="capitalize"></div>,
      content: (
        <InnerDialog
          activeTab={activeTab}
          handleProccessCase={async (newCode: MedicalReportCode) => {
            await MedicalService.updateMedicalReportProcessCodes(
              activeAccount?.id as string,
              reportProcess?.id as string,
              {
                state: MedicalReportProcessState.IN_PROGRESS,
                ...medicalReport,
                [activeTab === "opsCodes" ? "opsCodes" : "icdCodes"]: [
                  ...(activeTab === "opsCodes" ? reportProcess?.opsCodes || [] : reportProcess?.icdCodes || []),
                  {...newCode, linkedTexts: codeSentenceStore},
                ],
              }
            );
            triggerDialog(false);
            await refetch();
            reset();
          }}
          code={
            activeTab === "opsCodes"
              ? (reportProcess?.opsCodes?.length ? reportProcess.opsCodes[0].name.split("-")[0] : null) || null
              : (reportProcess?.icdCodes?.length ? reportProcess.icdCodes[0].name.split("-")[0] : null) || null
          }
        />
      ),
    });
  };

  const handleConfirmCase = async () => {
    if (!reportProcess?.opsCodes) return;
    const medicalReport: {
      opsCodes: MedicalReportCode[];
      icdCodes: MedicalReportCode[];
    } = {
      opsCodes: reportProcess?.opsCodes || [],
      icdCodes: reportProcess?.icdCodes || [],
    };
    try {
      setIsSubmitPending(true);
      await MedicalService.updateMedicalReportProcessCodes(
        activeAccount?.id as string,
        reportProcess?.id as string,
        {
          state: MedicalReportProcessState.COMPLETED,
          ...medicalReport,
        }
      );
    } catch (error) {
    } finally {
      await refetch();
      setIsSubmitPending(false);
    }
  };

  return (
    <div className="container py-6">
      <Tabs
        defaultValue={activeTab}
        onValueChange={() => {
          setSearchTextState("");
          reset();
          setActiveTab(activeTab === "icdCodes" ? "opsCodes" : "icdCodes");
        }}
      >
        <Link to="/cases">
          <div className="flex text-primary mb-10">
            <div className=" font-bold">{t("Arztberichte")}</div>
            <Icons.chevronRight />
            <div className="hidden md:flex">{reportProcess?.name || ""}</div>
          </div>
        </Link>
        <div className="grid sm:grid-cols-2 grid-cols-1 gap-6">
          {reportProcess?.state === MedicalReportProcessState.ERROR ? (
            <div className="text-center flex justify-center items-center">
              {t("error_medical_doc")}
            </div>
          ) : (
            <div className="flex flex-col space-y-6 ">
              <ScrollArea
                ref={scrollRef}
                className="h-[75vh] relative border rounded-md p-4"
              >
                {reportContentString && (
                  <PageDetailsEditor uploadedText={reportContentString || ""} />
                )}
              </ScrollArea>
              {codeSentenceStore.length > 0 ? (
                <div className="space-y-2">
                  {codeSentenceStore.map((codeSentence, index) => {
                    return (
                      <div className="flex items-center justify-between text-[12px]">
                        <div className="flex items-center">
                          <span className="px-4 py-3 mr-2 font rounded bg-[#0154FF] text-white">
                            {index + 1}
                          </span>{" "}
                          <p className="opacity-60 line-clamp-3">
                            {codeSentence}
                          </p>
                        </div>
                        <Button
                          variant={"ghost"}
                          className="ml-auto flex"
                          onClick={() => removeOpsCode(codeSentence)}
                        >
                          <Icons.deleteRing />
                        </Button>
                      </div>
                    );
                  })}
                  <Button
                    variant={"ghost"}
                    className="ml-auto flex"
                    onClick={() => handleAddCodeToList()}
                  >
                    <Icons.arrowRight />
                  </Button>
                </div>
              ) : null}
            </div>
          )}

          <div className="space-y-6">
            <div className="bg-primary/90 rounded-t-md text-primary px-2 py-4 justify-between items-center flex border-t gap-2">
              <div className="flex items-center">
                <Icons.medical className="w-10 h-10" />
                <div className="font-normal mx-2">
                  <div className="text-white text-sm md:text-medium">
                    {reportProcess?.name || ""}
                  </div>
                  {reportProcess?.updatedAt ? (
                    <div className="text-white/70 text-xs md:text-light">
                      {t("created", {
                        date: format(
                          new Date(reportProcess.createdAt),
                          "dd.MM.y, HH:mm"
                        ),
                        firstname: userInfo?.firstname,
                        lastname: userInfo?.lastname,
                      })}
                    </div>
                  ) : null}
                  {reportProcess?.tags?.length ? (
                    <div className="flex flex-row flex-wrap">
                      {reportProcess.tags.map((tag) => (
                        <Badge
                          key={tag}
                          variant="gray"
                          className="text-xs text-center mr-1 mt-1"
                        >
                          { tag }
                        </Badge>
                      ))}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="flex space-x-2">
                <Badge
                  variant={getStateVariant(reportProcess?.state)}
                  className="capitalize text-xs text-center"
                >
                  {reportProcess?.state
                    ? JSON.parse(t("statuses"))[reportProcess.state]
                    : "-"}
                  {user?.role === "Clinical Manager" && (reportProcess?.state === MedicalReportProcessState.IN_PROGRESS || reportProcess?.state === MedicalReportProcessState.COMPLETED || reportProcess?.state === MedicalReportProcessState.ARCHIVED) ? (
                    ` (${calculateMedicalReportMetrics(reportProcess).average.f1.toFixed(2)})`
                  ) : null}
                  {" "}
                  {reportProcess?.state === MedicalReportProcessState.DRAFT ||
                  reportProcess?.state ===
                    MedicalReportProcessState.PREPROCESSING ? (
                    <Icons.spinner className="animate-spin mx-1 h-4 w-4" />
                  ) : null}
                </Badge>
              </div>
            </div>
            <div className="space-y-10">
              {reportProcess?.state === MedicalReportProcessState.ERROR ? (
                <>
                  <div className="mt-20 p-20 space-y-6">
                    <div>{t("error_medical_report")}</div>
                    <div>+49 30 3333333</div>
                  </div>
                  <Button
                    className="w-full mt-2"
                    variant={"secondary"}
                    disabled
                  >
                    {t("finalise")}
                  </Button>
                </>
              ) : reportProcess?.state ===
                  MedicalReportProcessState.PREPROCESSING ||
                reportProcess?.state === MedicalReportProcessState.DRAFT ? (
                <div className="space-y-6">
                  <div>{t("proccessing_medical_report")}</div>
                  <div>
                    <div className="space-y-2">
                      <Skeleton className="h-10 w-full" />
                      <Skeleton className="h-10 w-full" />
                      <Skeleton className="h-10 w-full" />
                      <Skeleton className="h-10 w-full" />
                      <Skeleton className="h-10 w-full" />
                      <Skeleton className="h-10 w-full" />
                    </div>
                  </div>
                  <Button
                    className="w-full mt-2"
                    variant={"secondary"}
                    disabled
                  >
                    {t("finalise")}
                  </Button>
                </div>
              ) : reportProcess?.state === MedicalReportProcessState.READY ||
                reportProcess?.state === MedicalReportProcessState.COMPLETED ||
                reportProcess?.state ===
                  MedicalReportProcessState.IN_PROGRESS ? (
                <div>
                  <div className="flex items-center gap-4">
                    <TabsList>
                      <TabsTrigger value="opsCodes">OPS</TabsTrigger>
                      <TabsTrigger value="icdCodes">ICD</TabsTrigger>
                    </TabsList>
                    <Button
                      className="text-primary border-primary border-2"
                      variant={"outline"}
                      onClick={() => actionForOpsCodeHandler(null, "confirm")}
                    >
                      <Icons.add className="w-4 h-4" />
                    </Button>
                    <SearchForm
                      handleSelectedSearchResult={handleSelectedSearchResult}
                      activeSection={activeTab}
                      setSearchTextState={setSearchTextState}
                      searchTextState={searchTextState}
                    />
                  </div>
                  {/* OPS CODES start */}
                  <TabsContent value="opsCodes">
                    <div>
                      <ScrollArea className="h-[65vh]">
                        <div>
                          <div>
                            <div className="flex mt-4 mb-3 border-b pb-3 text-primary">
                              <div className="w-[150px]">Codes</div>
                              <div className="flex justify-between w-full">
                                <div className=" self-start place-self-start">
                                  Beschreibung
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="space-y-4 text-sm">
                            {(reportProcess?.opsCodes || [])
                              .sort((a, b) => {
                                if (a.name < b.name) return -1;
                                if (a.name > b.name) return 1;
                                return 0;
                              })
                              .map((code, i) => (
                                <div
                                  key={i}
                                  className="cursor-pointer flex justify-between hover:bg-muted/50 py-3 rounded-md items-center"
                                  onClick={() => findCodeHandler(code?.name)}
                                >
                                  <div className="flex items-center justify-center gap-4">
                                    <div
                                      className={cn(
                                        "font-medium h-full flex items-center"
                                      )}
                                    >
                                      <span
                                        className={cn(
                                          "mx-1 inline-block h-10 w-1 mr-2",
                                          code.status ===
                                            MedicalReportCodeState.UNCHECKED
                                            ? "bg-yellow-500"
                                            : code.status ===
                                              MedicalReportCodeState.CHECKED
                                            ? "bg-green-500"
                                            : ""
                                        )}
                                      />{" "}
                                      <div className="mr-2">
                                      {code.addedBy === MedicalReportCodeAddedBy.MANUAL?  (<Icons.user className="w-4 h-4" />) :
                                        (<Icons.star className="w-4 h-4" />)}
                                      </div>
                                      <span
                                        className={cn(
                                          code.status ===
                                            MedicalReportCodeState.REJECTED &&
                                            "line-through"
                                        )}
                                      >
                                        <div className="">{code.name}</div>
                                        <div className="text-xs text-muted-foreground">
                                          {code.description}
                                        </div>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="text-right flex justify-end space-x-2">
                                    <div className="flex">
                                      {code.status !==
                                      MedicalReportCodeState.UNCHECKED ? null : (
                                        <>
                                          <Button
                                            variant="ghost"
                                            className="text-green-500"
                                            onClick={() =>
                                              actionForOpsCodeHandler(
                                                code,
                                                "confirm"
                                              )
                                            }
                                          >
                                            <Icons.checkRing />
                                          </Button>
                                          <Button
                                            variant="ghost"
                                            onClick={() =>
                                              actionForOpsCodeHandler(
                                                code,
                                                "delete"
                                              )
                                            }
                                          >
                                            <Icons.deleteRing />
                                          </Button>{" "}
                                        </>
                                      )}

                                      <DropdownMenu>
                                        <DropdownMenuTrigger asChild>
                                          <Button
                                            variant="ghost"
                                            className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
                                          >
                                            <DotsHorizontalIcon className="h-4 w-4 rotate-90" />
                                            <span className="sr-only">
                                              Open menu
                                            </span>
                                          </Button>
                                        </DropdownMenuTrigger>
                                        <DropdownMenuContent
                                          align="end"
                                          className="min-w-fit"
                                        >
                                          {code.status ===
                                            MedicalReportCodeState.CHECKED ||
                                          code.status ===
                                            MedicalReportCodeState.UNCHECKED ? null : (
                                            <DropdownMenuItem>
                                              <Button
                                                variant="outline"
                                                className="text-green-500"
                                                onClick={() =>
                                                  actionForOpsCodeHandler(
                                                    code,
                                                    "confirm"
                                                  )
                                                }
                                              >
                                                <Icons.checkRing />
                                              </Button>
                                            </DropdownMenuItem>
                                          )}
                                          {code.status ===
                                            MedicalReportCodeState.REJECTED ||
                                          code.status ===
                                            MedicalReportCodeState.UNCHECKED ? null : (
                                            <DropdownMenuItem>
                                              <Button
                                                variant="outline"
                                                onClick={() =>
                                                  actionForOpsCodeHandler(
                                                    code,
                                                    "delete"
                                                  )
                                                }
                                              >
                                                <Icons.deleteRing />
                                              </Button>
                                            </DropdownMenuItem>
                                          )}
                                          <DropdownMenuItem>
                                            <Button
                                              variant="outline"
                                              onClick={() =>
                                                actionForOpsCodeHandler(
                                                  code,
                                                  "switch"
                                                )
                                              }
                                            >
                                              <Icons.refresh />
                                            </Button>
                                          </DropdownMenuItem>
                                        </DropdownMenuContent>
                                      </DropdownMenu>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </ScrollArea>
                    </div>
                  </TabsContent>
                  {/* OPS CODES end */}
                  {/* ICD CODES start */}
                  <TabsContent value="icdCodes">
                    <div>
                      <ScrollArea className="h-[65vh]">
                        <div>
                          <div>
                            <div className="flex mb-3 mt-4 text-primary border-b pb-3">
                              <div className="w-[150px]">Codes</div>
                              <div className="flex justify-between w-full">
                                <div className=" self-start place-self-start">
                                  Beschreibung
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="space-y-4 text-sm">
                            {(reportProcess?.icdCodes || [])
                              .sort((a, b) => {
                                if (a.name < b.name) return -1;
                                if (a.name > b.name) return 1;
                                return 0;
                              })
                              .map((code, i) => (
                                <div
                                  key={i}
                                  className="cursor-pointer flex justify-between hover:bg-muted/50 py-3 rounded-md items-center"
                                  onClick={() => findCodeHandler(code?.name)}
                                >
                                  <div className="flex items-center justify-center gap-4">
                                    <div
                                      className={cn(
                                        "font-medium h-full flex items-center"
                                      )}
                                    >
                                      <span
                                        className={cn(
                                          "mx-1 inline-block h-10 w-1 mr-2",
                                          code.status ===
                                            MedicalReportCodeState.UNCHECKED
                                            ? "bg-yellow-500"
                                            : code.status ===
                                              MedicalReportCodeState.CHECKED
                                            ? "bg-green-500"
                                            : ""
                                        )}
                                      />{" "}
                                      <div className="mr-2">
                                        {code.addedBy === MedicalReportCodeAddedBy.MANUAL?  (<Icons.user className="w-4 h-4" />) :
                                        (<Icons.star className="w-4 h-4" />)}
                                      </div>
                                      <span
                                        className={cn(
                                          "w-20",
                                          code.status ===
                                            MedicalReportCodeState.REJECTED &&
                                            "line-through"
                                        )}
                                      >
                                        {code.name}
                                      </span>
                                    </div>
                                    <div
                                      className={cn(
                                        code.status ===
                                          MedicalReportCodeState.REJECTED &&
                                          "line-through"
                                      )}
                                    >
                                      {code.description}
                                    </div>
                                  </div>
                                  <div className="text-right flex justify-end space-x-2">
                                    <div className="flex">
                                      {code.status !==
                                      MedicalReportCodeState.UNCHECKED ? null : (
                                        <>
                                          <Button
                                            variant="ghost"
                                            className="text-green-500"
                                            onClick={() =>
                                              actionForOpsCodeHandler(
                                                code,
                                                "confirm"
                                              )
                                            }
                                          >
                                            <Icons.checkRing />
                                          </Button>
                                          <Button
                                            variant="ghost"
                                            onClick={() =>
                                              actionForOpsCodeHandler(
                                                code,
                                                "delete"
                                              )
                                            }
                                          >
                                            <Icons.deleteRing />
                                          </Button>{" "}
                                        </>
                                      )}

                                      <DropdownMenu>
                                        <DropdownMenuTrigger asChild>
                                          <Button
                                            variant="ghost"
                                            className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
                                          >
                                            <DotsHorizontalIcon className="h-4 w-4 rotate-90" />
                                            <span className="sr-only">
                                              Open menu
                                            </span>
                                          </Button>
                                        </DropdownMenuTrigger>
                                        <DropdownMenuContent
                                          align="end"
                                          className="min-w-fit"
                                        >
                                          {code.status ===
                                            MedicalReportCodeState.CHECKED ||
                                          code.status ===
                                            MedicalReportCodeState.UNCHECKED ? null : (
                                            <DropdownMenuItem>
                                              <Button
                                                variant="outline"
                                                className="text-green-500"
                                                onClick={() =>
                                                  actionForOpsCodeHandler(
                                                    code,
                                                    "confirm"
                                                  )
                                                }
                                              >
                                                <Icons.checkRing />
                                              </Button>
                                            </DropdownMenuItem>
                                          )}
                                          {code.status ===
                                            MedicalReportCodeState.REJECTED ||
                                          code.status ===
                                            MedicalReportCodeState.UNCHECKED ? null : (
                                            <DropdownMenuItem>
                                              <Button
                                                variant="outline"
                                                onClick={() =>
                                                  actionForOpsCodeHandler(
                                                    code,
                                                    "delete"
                                                  )
                                                }
                                              >
                                                <Icons.deleteRing />
                                              </Button>
                                            </DropdownMenuItem>
                                          )}
                                          <DropdownMenuItem>
                                            <Button
                                              variant="outline"
                                              onClick={() =>
                                                actionForOpsCodeHandler(
                                                  code,
                                                  "switch"
                                                )
                                              }
                                            >
                                              <Icons.refresh />
                                            </Button>
                                          </DropdownMenuItem>
                                        </DropdownMenuContent>
                                      </DropdownMenu>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </ScrollArea>
                    </div>
                  </TabsContent>
                  {/* ICD CODES end */}
                  <Button
                    className="w-full mt-2"
                    variant={"secondary"}
                    disabled={
                      !allOpsCodesCHECKED || !allIcdCodesCHECKED || isSubmitPending || reportProcess.state !== MedicalReportProcessState.IN_PROGRESS
                    }
                    onClick={handleConfirmCase}
                  >
                    {t("finalise")}
                    {isSubmitPending && (
                      <Icons.spinner className="animate-spin mx-2" />
                    )}
                  </Button>
                </div>
              ) : (
                <div>No states found</div>
              )}
            </div>
          </div>
        </div>
      </Tabs>
    </div>
  );
};

export default CasePageDetails;
