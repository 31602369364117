import { QueryObserverResult, useQuery } from "@tanstack/react-query";

import { MedicalReportProcess } from "@models/medical-report-process";
import * as MedicalReportService from "@services/medical-report.service";


const useGetMedicalReportProcess = (id: string, accountId: string): { data: MedicalReportProcess | null, isPending: boolean, refetch: () => Promise<QueryObserverResult> } => {
  const { data, isPending, refetch } = useQuery({
    queryKey: ["MedicalReportProcess", accountId, id],
    queryFn: () => id ? MedicalReportService.getMedicalReportProcess(accountId, id) : null,
    refetchInterval: 20000, // 20 sec
  });

  return {
    data: data || null,
    isPending,
    refetch,
  };
};

export default useGetMedicalReportProcess;
