import "@src/styles/global.css";

import { AppContainer } from "@components/app-container.tsx";
import { useAuth } from "@hooks/useAuth";
import DashboardPage from "@pages/dashboard";
import ErrorPage from "@pages/errorPage.tsx";
import Login from "@pages/login.tsx";
import { Route, Routes, useLocation, useSearchParams } from "react-router-dom";
import CasePageDetails from "@pages/case-page-details";
import i18n from "@config/i18n";
import { useEffect } from "react";

export const PAGES_ROUTES = {
  MAIN: "/",
  PROFILE: "/profile",
  LOGIN: "/login",
};

const Router = () => {
  const location = useLocation();
  const [params] = useSearchParams();
  const language: "en" | "de" | null | string = params.get("lang");

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);
  useAuth();
  return (
    <>
      <Routes location={location}>
        <Route path="/" element={<AppContainer />}>
          <Route index element={<DashboardPage />} />
          <Route path="cases" element={<DashboardPage />} />
          <Route path="cases/:id/details" element={<CasePageDetails />} />
          <Route path="expenses" element={<div>Expense</div>} />
          <Route path="crypto" element={<div>Crypto</div>} />
        </Route>
        
        <Route path="/sign-in" element={<Login />} />
        <Route path="/sign-up" element={<Login />} />
        <Route path="404" element={<ErrorPage errorCode={404} />} />
        <Route path="403" element={<ErrorPage errorCode={403} />} />
        <Route path="*" element={<ErrorPage errorCode={404} />} />
      </Routes>
    </>
  );
};

export default Router;
