import { Icons } from "@components/icons";
import { useSidebarStore } from "@store/sidebar.store";

const SidebarToggler = () => {
  const { toggleSidebar, showSidebar } = useSidebarStore((state) => state);
  return (
    <button
      type="button"
      className="sidebar-toggler absolute bottom-4 left-4 rounded-full justify-center items-center h-10 w-10 bg-background flex invisible md:visible"
      onClick={()=>toggleSidebar()}
    >
      {showSidebar === "close" ? (
        <Icons.chevronRight className="fill-foreground w-5 h-5" />
      ) : (
        <Icons.chevronLeft className="fill-foreground w-5 h-5" />
      )}
    </button>
  );
};

export default SidebarToggler;