import { cn } from "@lib/utils";
import { cva } from "class-variance-authority";
import { motion } from "framer-motion";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
const spring = {
  type: "spring",
  stiffness: 300,
  damping: 30,
};
const sidebarItemVariants = cva(
  "flex items-center md:justify-normal justify-center p-3 pr-8 text-sm font-medium rounded-r-lg transition-all ",
  {
    variants: {
      active: {
        default: "",
        active: "bg-muted/10 text-brand",
      },
    },
    defaultVariants: {
      active: "default",
    },
  },
);

const SidebarItem = ({
  icon,
  header,
  active = false,
  className,
}: {
  icon: JSX.Element;
  header: string;
  active?: boolean;
  className?: string | undefined;
}) => {
  useEffect(() => {

  }, [])

  const { t } = useTranslation();
  return (
    <motion.div
      title={header}
      whileHover={{ scale: 1.02 }}
      className={cn(
        "relative",
        sidebarItemVariants({
          active: active ? "active" : "default",
          className,
        }),
      )}
    >
      <span className="pr-2 ml-2">{icon}</span>
      <div className="sidebar-step-title hidden md:block capitalize">
        {t(header)}
      </div>
      {active && (
        <motion.div
          layoutId="line"
          className="absolute border left-0 h-full border-l-4 border-brand z-20"
          initial={false}
          transition={spring}
        ></motion.div>
      )}
    </motion.div>
  );
};

export default SidebarItem;
