import { Sidebar } from "@components/sidebar/sidebar";
import { Outlet } from "react-router-dom";


export const AppContainer = () => {
  return (
    <>
      <div className="flex bg-gradient-to-r from-purple-500 to-pink-500" style={{background: "linear-gradient(158deg, rgb(63 46 46 / 10%), rgb(255 126 126 / 23%) 900px, rgba(255, 255, 255, 0.96))"}}>
        <Sidebar />
        <div className="overflow-y-auto w-full bg-background m-4 rounded-lg mt-24 sm:mt-4">
          <Outlet />
        </div>
      </div>
    </>
  );
};
