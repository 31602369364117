import { Link, useLocation } from "react-router-dom";

type NavLinkProps = {
  to: string;
  children: ((props: { active: boolean }) => React.ReactNode) | React.ReactNode;
};

const NavigationLink = ({ to, children }: NavLinkProps) => {
  const location = useLocation();
  const active = location.pathname === to;
  return (
    <Link to={to}>
      {typeof children === "function" ? children({ active }) : children}
    </Link>
  );
};

export default NavigationLink;
