import { create } from "zustand";

interface DialogState {
  showDialog: boolean;
  header: JSX.Element | null;
  content: JSX.Element | JSX.Element[] | null | string;
  setHeader: (header: JSX.Element | null) => void;
  callback?: () => void;
  triggerDialog: (showDialog: boolean) => void;
  show: ({
    content,
    callback,
  }: {
    content: JSX.Element | JSX.Element[] | null | string;
    callback?: () => void;
    header: JSX.Element | null
  }) => void;
}

export const useDialogStore = create<DialogState>()((set) => ({
  showDialog: false,
  content: null,
  header: null,
  show: ({ content, callback, header }) =>
    set((state) => ({
      ...state,
      content: content,
      header: header,
      callback: callback,
      showDialog: true,
    })),
  triggerDialog: (showDialog) =>
    set(() => ({
      showDialog: showDialog,
    })),
    setHeader(header) {
      set(() => ({header}))
    },
}));
