"use client";

import { Icons } from "@components/icons";
import { Input } from "@components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@ui/button";
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@ui/form";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import * as z from "zod";

export default function Login() {
	const [loginState, setLoginState] = useState({ isLoading: false, error: "" });
	const { t } = useTranslation("common");
	const formSchema = z.object({
		name: z.string().min(2, t("login.name_invalid") ?? ""),
		email: z.string().email(t("login.email_invalid") ?? ""),
		password: z.string().min(6, t("login.password_invalid") ?? ""),
	});
	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			name: "",
			email: "",
			password: "",
		},
	});

	async function onSubmit({

	}: z.infer<typeof formSchema>) {
		setLoginState({ ...loginState, isLoading: true });
		// const { error } = await Auth.signUp({
		// 	email: email,
		// 	password: password,
		// 	fullName: name,
		// 	redirectTo: currentPath,
		// });
		// if (error) {
		// 	setLoginState({ isLoading: false, error });
		// 	return;
		// }

		// setLoginState({ isLoading: false, error: "" });
		// navigate("/sign-up-success", { state: { email } });
	}

	return (
		<Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
				<FormField
					control={form.control}
					name="name"
					render={({ field }) => (
						<FormItem>
							<FormLabel>{t("login.name")}</FormLabel>
							<FormControl>
								<Input placeholder="John" {...field} />
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>
				<FormField
					control={form.control}
					name="email"
					render={({ field }) => (
						<FormItem>
							<FormLabel>{t("login.email")}</FormLabel>
							<FormControl>
								<Input placeholder="john.doe@doe.com" {...field} />
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>
				<FormField
					control={form.control}
					name="password"
					render={({ field }) => (
						<FormItem className="mt-0">
							<FormLabel>{t("login.password")}</FormLabel>
							<FormControl>
								<Input placeholder="*******" type="password" {...field} />
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>
				<Button
					className="my-4 w-full"
					type="submit"
					disabled={loginState.isLoading}
				>
					{t("submit")}
					{loginState.isLoading && (
						<Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
					)}
				</Button>
				<span className="text-xs flex justify-items-end">
					<Link to="/sign-in">{t("login.has_account_sign_in_here")}</Link>
				</span>
			</form>
		</Form>
	);
}
