import "@config/i18n.ts";
import "@src/styles/global.css";
import { queryClient } from "@config/query.config";
import Router from "@config/router";
import { ThemeProvider } from "@hooks/useTheme";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Toaster } from "sonner";
import Sheet from "@components/slide-sheet";

// Dynamically load the script if in production
if (import.meta.env.VITE_ENV === 'production') {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.src = "https://www.clarity.ms/tag/mbez9qfb1i";
  document.head.appendChild(script);
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider defaultTheme="light">
          <BrowserRouter>
            <Router />
            <Toaster richColors closeButton />
            <Sheet />
          </BrowserRouter>
        </ThemeProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
  </React.StrictMode>,
);
