import {Icons} from "@components/icons";
import type {
  ComboBoxProps,
  ListBoxItemProps,
  ValidationResult,
} from "react-aria-components";
import {
  FieldError,
  ListBoxItem,
  Text,
  Button,
  ComboBox,
  Input,
  ListBox,
  Popover,
  Label,
} from "react-aria-components";

interface MyComboBoxProps<T extends object>
  extends Omit<ComboBoxProps<T>, "children"> {
  label?: string;
  placeholder?: string;
  description?: string | null;
  selectionBehavior?: "replace" | "toggle";
  errorMessage?: string | ((validation: ValidationResult) => string);
  children: React.ReactNode | ((item: T) => React.ReactNode);
  isLoading?: boolean;
}

export function MyComboBox<T extends object>({
  label,
  description,
  errorMessage,
  children,
  placeholder,
  selectionBehavior,
  isLoading = false,
  ...props
}: MyComboBoxProps<T>) {
  return (
    <ComboBox {...props}>
      <Label>{label}</Label>
      <div className="border border-gray-300 rounded-md p-2 flex items-center">
        <Button className="mr-2">
          <Icons.search />
        </Button>
        <Input placeholder={placeholder ? placeholder : undefined} className={"border-0 focus:outline-none focus:ring-0"} />
        {isLoading && <Icons.spinner className="animate-spin text-muted-foreground flex self-end ml-auto" />}
      </div>
      {description && <Text slot="description">{description}</Text>}
      <FieldError>{errorMessage}</FieldError>
      <Popover className="bg-background p-4 shadow-2xl border rounded-md">
        <ListBox selectionBehavior={selectionBehavior}>{children}</ListBox>
      </Popover>
    </ComboBox>
  );
}

export function MyItem(props: ListBoxItemProps) {
  return (
    <ListBoxItem
      {...props}
      className={({isFocused, isSelected}) =>
        `my-item ${isFocused ? "focused" : ""} ${isSelected ? "selected" : ""}`
      }
    />
  );
}
