import { useQuery } from "@tanstack/react-query";

import * as IcdService from "@services/icd.service";
import { IcdClass } from "@models/icd-class";
import { IcdModifierClass } from "@models/icd-modifier";


const useGetIcdClass = (id: string | null): { data: { class?: IcdClass, modifierClasses?:  IcdModifierClass[] }, isPending: boolean } => {
  const { data, isPending } = useQuery({
    queryKey: ["IcdClass", id],
    queryFn: () => id ? IcdService.getIcdClass(id) : null,
  });

  return {
    data: data || { class: undefined, modifierClasses: undefined },
    isPending,
  };
};

export default useGetIcdClass;
