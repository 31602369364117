import { useQuery } from "@tanstack/react-query";
import * as UserService from "@services/user.service";
import { User } from "@models/user";


const useGetUserId = (id: string): { data: User | undefined, isPending: boolean } => {
  const { data, isPending } = useQuery({
    queryKey: ["userInfo", id],
    queryFn: () => UserService.getUser(id),
  });

  return {
    data,
    isPending,
  };
};

export default useGetUserId;
