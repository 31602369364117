
export enum OpsRubricKind {
  EXCLUSION = 'exclusion',
  INCLUSION = 'inclusion',
  NOTE = 'note',
  PREFERRED = 'preferred',
  PREFERRED_LONG = 'preferredLong',
}

/**
 * The Rubric object within the OPS model.
 */
export interface OpsRubric {
  /** The kind of this object. */
  kind: OpsRubricKind;
  /** The text. */
  text: string;
}
