import {useUserStore} from "@store/user.store";
import {Button} from "@ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@ui/dropdown-menu";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

const SidebarBadge = () => {
  const {user, setUser, activeAccount, accounts, setActiveAccount, setAccounts, setToken} =
    useUserStore();
  const navigate = useNavigate();
  const {t} = useTranslation();
  const logoutHandler = () => {
    setUser(null);
    setActiveAccount(null);
    setAccounts(null);
    setToken(null);
    navigate("/sign-in");
  };
  const handleChangeAccount = (id: string) => {
    const selectedAccount = accounts?.find((account) => account.id == id);
    if (!selectedAccount) return;
    setActiveAccount(selectedAccount);
  };
  return (
    <div className="h-10 m-4">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <div>
            <Button
              variant="secondary"
              className="tex-sm account-name border rounded-full"
            >
              {activeAccount?.name?.split("")?.[0]}
            </Button>
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56" align="end" forceMount>
          <DropdownMenuLabel className="font-normal">
            <div className="flex flex-col space-y-1">
              <p className="text-sm leading-none">
                {user?.firstname} {user?.lastname}
              </p>
              <p className="text-xs leading-none text-muted-foreground">
                {user?.email}
              </p>
            </div>
          </DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            {accounts?.map((account) => {
              return (
                <DropdownMenuItem
                  key={account.name}
                  onClick={() => handleChangeAccount(account.id)}
                  data-disabled={
                    account.id === activeAccount?.id ? "true" : undefined
                  }
                >
                  {account.name}
                </DropdownMenuItem>
              );
            })}
          </DropdownMenuGroup>
          <DropdownMenuSeparator />
          <DropdownMenuItem onClick={logoutHandler}>
            {t("log_out")}
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export default SidebarBadge;
