import { AxiosRequestConfig } from "axios";

const noCacheHeaders = {
  "Cache-Control": "no-cache",
  Pragma: "no-cache",
  Expires: 0,
};

export const axiosConfig = (
  token: string | null,
  opts?: AxiosRequestConfig,
  noCache = false,
  headers: AxiosRequestConfig["headers"] = {},
): AxiosRequestConfig => ({
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...(noCache && noCacheHeaders),
    ...headers,
  },
  ...opts,
});
