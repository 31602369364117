import { QueryListOptions } from "@services/base-query.service";


export function toQueryStrings(options?: QueryListOptions): string {
  let queryOptions: string[] = [];

  if (options) {
    for (const [key, value] of Object.entries(options as {[key: string]: string | string[] | number | undefined})) {
      if (value !== undefined) {
        queryOptions.push(`${key}=${Array.isArray(value) ? value.join(',') : value}`);
      }
    }
  }

  return queryOptions.join('&');
}
