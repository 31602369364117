import { IcdClass } from "@models/icd-class";
import { IcdModifierClass } from "@models/icd-modifier";
import { Tab } from "@pages/case-page-details";
import useGetIcdClass from "./useGetIcdClass";
import useGetOpsClass from "./useGetOpsClass";
import { OpsClass } from "@models/ops-class";
import { OpsModifierClass } from "@models/ops-modifier";


const useGetOPSorICDClass = (id: string | null, activeTab: Tab): { data: { class?: IcdClass | OpsClass, modifierClasses?: IcdModifierClass[] | OpsModifierClass[] }, isPending: boolean } => {
    if (activeTab === "icdCodes") {
        return useGetIcdClass(id);
    } else  {
        return useGetOpsClass(id);
    }
 
};

export default useGetOPSorICDClass;
